<template>
  <div class="report-list">
    <!-- 强制报告列表 -->
    <!-- 报告内容 -->
    <div
      class="content"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.2)"
      style="width: 100%"
    >
      <div style="width: 100%; display: flex; justify-content: center">
        <template v-if="ReportList.length > 0 ? false : true">
          <a-row
            type="flex"
            justify="center"
            style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
          >
            <el-empty :description="description"></el-empty>
          </a-row>
        </template>
      </div>
      <el-card
        class="box-card temp"
        v-for="(item, index) in ReportList"
        :key="index.id"
      >
        <el-form ref="form" label-width="100px" label-position="left">
          <el-form-item label="报告人">
            <span>{{ item.name }}</span>
          </el-form-item>
          <el-form-item label="报告情形">
            <span>{{ item.situation }}</span>
          </el-form-item>
          <el-form-item label="联系方式">
            <span>{{ item.tel }}</span>
          </el-form-item>
          <el-form-item label="创建时间">
            <span>{{ item.createTime }}</span>
          </el-form-item>
        </el-form>
        <el-button type="goon" class="btn" @click="getReportDetails(item.id)"
          >查看详情</el-button
        >
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ReportList: [],
      empty: true,
      description: "暂无数据",
    };
  },
  methods: {
    // 获取我的强制报表数据
    getData() {
      this.loading = true;
      this.$HTTP
        .httpToken({
          url: "/shouwei/report/getMyReportRecordList",
          method: "post",
          data: {},
        })
        .then((res) => {
          this.loading = false;
          //   loading.close();
          console.log("111", res);
          if (res.code == 0) {
            // this.$elementMessage({
            //   showClose: true,
            //   message: "提交报告成功",
            //   type: "success",
            // });
            let { reportRecordList } = res.data;
            this.ReportList = [...reportRecordList];
            this.empty = false;
            console.log(this.ReportList);
          } else {
            this.$elementMessage.error(res.msg);
          }
        })
        .catch((error) => {
          //   loading.close();
          this.$elementMessage.error(error);
        });
    },
    // 点击查看详情
    getReportDetails(id) {
      this.$router.push({ name: "MyReportDetails", query: { id } });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  width: 32%;
  margin-right: 2%;
  &:nth-child(3n + 2) {
    margin-right: 0;
  }
}
.content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  .box-card {
    margin-bottom: 20px;
  }
}
.el-card {
  position: relative;
  .el-form {
    padding-bottom: 20px;
  }
}
.btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

// el-button颜色更改
.el-button--goon.is-active,
.el-button--goon:active {
  background: #37b6df;
  border-color: #37b6df;
  color: #fff;
}

.el-button--goon:focus,
.el-button--goon:hover {
  background: #37b9ff;
  border-color: #37b9ff;
  color: #fff;
}

.el-button--goon {
  color: #fff;
  background-color: #37b6df;
  border-color: #37b6df;
}
.el-form-item__label {
  text-align: left;
}
</style>