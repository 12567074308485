<template>
    <div class="my-order-list">
        <a-row class="content" v-loading="loading" :gutter="24" style="margin-top: 20px; width: 100%; margin-left: 0"
            element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)">
            <!-- <div style="width: 100%; display: flex; justify-content: center">
                <template v-if="feedbackList.length <= 0">
                    <a-row type="flex" justify="center"
                        style="margin-bottom: 10px; margin-top: 10px; min-height: 400px">
                        <el-empty :description="description"></el-empty>
                    </a-row>
                </template>
            </div> -->
            <div>
                <div class="form-title-font">
                    报告说明
                </div>
                <a-textarea v-model="formData.contents" placeholder="请详细描述报告情况" :auto-size="{ minRows: 12 }"
                    style="border: none; background-color: #f4f4f4; padding: 10px 20px;width: 100%;margin-top: 15px;" />

                <div class="media-content">
                    <div class="form-title-font" style="margin: 20px 0px">
                        上传图片/视频
                    </div>
                    <el-upload action="https://fileprod.skillhub.top/file/batchFileUploadImgVideo" :limit="9"
                        :accept="'image/*,video/*'" :before-upload="beforeuploadMainGraph"
                        :on-success="handleSuccessMainGraph" :on-preview="handlePreviewMainGraph"
                        :before-remove="beforeremoveMainGraph" :on-remove="handleRemoveMainGraph" list-type="picture-card"
                        :file-list="fileList" :multiple="true">
                        <i class="el-icon-plus spc1"></i>
                    </el-upload>
                    <!-- 图片放大时候用的 -->
                    <el-dialog :visible.sync="dialogVisibleMainGraph" class="spc12">
                        <img width="100%" :src="imageUrl" alt="" />
                    </el-dialog>
                    <!-- 視頻放大时候用的 -->
                    <el-dialog :visible.sync="dialogVisibleMainGraphVideo" class="spc12">
                        <video :src="videoPath" controls></video>
                    </el-dialog>
                </div>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 60px;margin-bottom: 40px;">
                <a-button style="border-radius: 25px;
                      background: linear-gradient(90deg, #51d2be, #0090d7);
                      font-size: 15px;
                      color: #fefefe;
                      font-weight: bold;
                      height: 50px;
                      width: 340px;
                      border: none;
                    " @click="submitFeedback">提交反馈
                </a-button>
            </div>
        </a-row>
    </div>
</template>

<script>
import { getWindowVersion, getMacVersion, getBrowser } from "../../utils/index";
import { mapMutations, mapState } from "vuex";
export default {
    data() {
        return {
            empty: true,
            loading: false,
            description: "暂无数据",
            dialogVisible: false,
            feedbackList: [],
            formData: {
                contents: '',
                img: [],
                phoneBrand: '',
                phoneSystemName: '',
                phoneSystemVersion: '',
                phoneVersion: '',
            },
            dialogVisibleMainGraph: false,
            dialogVisibleMainGraphVideo: false,
            imageUrlList: [],
            imageUrl: "",
            videoPath: "",
            fileList: [],
        };
    },
    computed: {
        ...mapState(["fullWidth"]),
    },
    created() {
        this.getBrowserInfo();
    },
    methods: {
        // 获取相关浏览器信息
        getBrowserInfo() {
            // 获取浏览器名称和版本号
            this.formData.phoneBrand = getBrowser().type;
            this.formData.phoneVersion = getBrowser().versions;
            if (getWindowVersion()) {
                this.formData.phoneSystemName = 'windows';
                this.formData.phoneSystemVersion = 'Win' + getWindowVersion();
            }
            if (getMacVersion()) {
                this.formData.phoneSystemName = 'mac';
                this.formData.phoneSystemVersion = 'Mac' + getMacVersion();
            }
        },
        // el-upload方法占位
        beforeuploadMainGraph(file) {
            console.log(file);
            let _this = this;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                let newUrl = reader.result; //图片路径
                console.log(newUrl); //这个就是base64格式的了
            };
        },
        //上传成功
        handleSuccessMainGraph(response, file, fileList) {
            console.log(file);
            console.log('response :>> ', response);
            console.log('fileList :>> ', fileList);
            this.fileList = fileList;
            this.imageUrl = response.data[0];

            this.formData.img.push(this.imageUrl);

            if (this.imageUrl && this.imageUrl.indexOf(".mp4") != -1) {
                let path =
                    "https://fileprod.skillhub.top/file/getVideoFileCoverBySizeV3?videoFileUrl=" +
                    this.imageUrl;
                console.log("视频地址:" + path);
                this.videoPath = this.imageUrl;
                this.imageUrl = path;
                file.url = this.imageUrl;
                console.log("最后", this.imageUrl);
            }
            console.log('this.formData.img :>> ', this.formData.img);
        },
        //点击文件列表中已上传的文件
        handlePreviewMainGraph(file) {
            console.log("111", file);
            if (file.response.data[0].indexOf(".mp4") != -1) {
                this.dialogVisibleMainGraphVideo = true;
            } else {
                this.imageUrl = file.url;
                this.dialogVisibleMainGraph = true;
            }
        },
        //删除文件前
        beforeremoveMainGraph(file, fileList) { },
        //删除文件时
        handleRemoveMainGraph(file, fileList) {
            this.imageUrlList = fileList;
            let tempList = [];
            fileList.forEach((v, i) => {
                tempList.push(v.response.data[0]);
            })
            this.formData.img = tempList;
            console.log('this.formData.img :>> ', this.formData.img);
        },
        // 点击提交反馈
        submitFeedback() {
            const loading = this.$loading.service({
                lock: true,
                text: "正在提交报告...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let _self = this;
            this.$HTTP
                .httpToken({
                    url: "/feedback/add",
                    data: this.formData,
                    method: "post",
                })
                .then((res) => {
                    loading.close();
                    if (res.code == 0) {
                        this.$elementMessage({
                            showClose: true,
                            message: "意见反馈提交成功",
                            type: "success",
                        });
                        this.fileList = [];
                        this.formData.img = [];
                        this.formData.contents = '';
                    } else {
                        this.$elementMessage.error(res.msg);
                    }
                })
                .catch((error) => {
                    loading.close();
                    this.$elementMessage.error(error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.form-title-font {
    font-size: 16px;
    color: #101010;
    font-weight: bold;
    text-align: left;
}

.form-value-font {
    font-size: 14px;
    color: #101010;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
}

.content {
    width: 100%;

    .box-card {
        margin-bottom: 20px;
    }
}

.el-card {
    position: relative;

    .el-form {
        padding-bottom: 20px;
    }
}
</style>