<template>
  <div class="bg-class">
    <div class="bg-class--from">
      <el-upload action="https://fileprod.skillhub.top/file/imageFileUpload/" :on-success="handleAvatarSuccess"
        :show-file-list="false" :multiple="false" :limit="1" accept=".jpg,.png,.jpeg">
        <div style="
              width: 90px;
              height: 90px;
              position: relative;
              border-radius: 45px;
              overflow: hidden;
              background-color: white;
              margin-bottom: 30px;
            ">
          <img :src="ruleForm.avatar" mode="aspectFill" style="border-radius: 45px; width: 90px; height: 90px" />
          <div class="absolute-bottom" style="
                font-size: 12px;
                font-weight: 12px;
                width: 100%;
                text-align: center;
                background-color: rgba(165, 167, 178, 0.5);
                height: 20px;
                color: white;
              ">
            设置头像
          </div>
        </div>
      </el-upload>

      <el-form :model="ruleForm" label-width="70px" :rules="rules" ref="ruleForm">
        <el-form-item label="昵称" prop="nickName">
          <el-input clearable v-model.number="ruleForm.nickName" placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realName">
          <el-input clearable v-model.number="ruleForm.realName" placeholder="真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input clearable v-model.number="ruleForm.phone" placeholder="请输入11位手机号"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input clearable v-model="ruleForm.code" placeholder="请输入4位验证码">
            <template slot="append">
              <el-button type="primary" :disabled="sendCodeStatus" :loading="sendCodeLoading" @click="sendCode">{{
                sendCodeTime == 60 ? "发送验证码" : `${sendCodeTime}秒后重发`
              }}</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="输入密码" prop="password">
          <el-input clearable v-model="ruleForm.password" show-password placeholder="请输入6-16位置密码"></el-input>
        </el-form-item>
        <el-form-item label="确定密码" prop="secondPassword">
          <el-input clearable v-model="ruleForm.secondPassword" show-password placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" prop="locCity">
          <el-cascader v-model="ruleForm.locCity" :options="options" :props="{ value: 'name', label: 'name' }"
            @change="handleChange" style="width: 100%"></el-cascader>
        </el-form-item>
        <el-form-item label="所在街道" v-if="streets.length > 0 ? true : false">
          <el-cascader v-model="ruleForm.villagesTownsName" :options="streets" :props="{ value: 'name', label: 'name' }"
            @change="streetChangeOn" style="width: 100%"></el-cascader>
        </el-form-item>
      </el-form>
    </div>
    <el-button type="primary" :disabled="submitLoading" :loading="submitLoading" round class="submitButton"
      @click="onSubmit">注册</el-button>
  </div>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入6-16位新密码!"));
      } else {
        if (value.length < 6 || value.length > 16) {
          callback(new Error("请输入6-16位新密码!"));
        } else {
          this.$refs.ruleForm.validateField("secondPassword");
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validateMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("手机号格式错误"));
        } else {
          console.log(value);
          if (`${value}`.length != 11) {
            callback(new Error("请输入11位手机号"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        if (`${value}`.length != 4) {
          callback(new Error("请输入4位验证码"));
        } else {
          callback();
        }
      }
    };
    var validateNickName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入昵称"));
      } else {
        callback();
      }
    };
    var validateRealName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入真实姓名"));
      } else {
        callback();
      }
    };
    var validateLocCity = (rule, value, callback) => {
      if (value.length == 0) {
        callback(new Error("请选择所在地区"));
      } else {
        callback();
      }
    };
    return {
      formLabelWidth: 100,
      sendCodeTime: 60,
      sendCodeLoading: false,
      submitLoading: false,
      sendCodeStatus: false,
      cityCode: "",
      streets: [],
      options: [],
      ruleForm: {
        avatar: "https://swlmapph5prod.skillhub.top/sw-static/default_regist_avatar.png",
        phone: "",
        password: "",
        secondPassword: "",
        code: "",
        realName: "",
        nickName: "",
        locCity: [],
        loginType: "registerLogin",
        villagesTownsName: [],
        registerDevType: "5",
      },
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        secondPassword: [{ validator: validatePass2, trigger: "blur" }],
        phone: [{ validator: validateMobile, trigger: "change" }],
        code: [{ validator: validateCode, trigger: "blur" }],
        nickName: [{ validator: validateNickName, trigger: "blur" }],
        realName: [{ validator: validateRealName, trigger: "blur" }],
        locCity: [{ validator: validateLocCity, trigger: "blur" }],
      },
    };
  },
  emits: ["modifySuccessEvent"],
  mounted() {
    this.getAddressListAction();
  },
  methods: {
    handleAvatarSuccess(response, file, fileList) {
      console.log(this.ruleForm);
      this.ruleForm.avatar = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join("");
      console.log(this.ruleForm.avatar);
    },

    onSubmit() {
      var _self = this;
      console.log("点击提交");
      this.$refs["ruleForm"].validate((success, object) => {
        if (success) {
          _self.submitLoading = true;
          let data = {
            avatar: _self.ruleForm.avatar,
            mobile: _self.ruleForm.phone,
            password: md5(_self.ruleForm.password).toLowerCase(),
            code: _self.ruleForm.code,
            realName: _self.ruleForm.realName,
            nickName: _self.ruleForm.nickName,
            locCity: _self.ruleForm.locCity.join(" "),
            villagesTownsName: _self.ruleForm.villagesTownsName.join(" "),
            loginType: "registerLogin",
            registerDevType: "5",
          };
          _self.$HTTP
            .httpAppKey({
              url: "/shouwei/userAdd/registerUserOrLogin",
              data: data,
              method: "post",
            })
            .then((res) => {
              _self.submitLoading = false;
              if (res.code == 0) {
                _self.$elementMessage.success("注册成功");
                _self.$emit("registSuccessEvent", res.data);
              } else {
                _self.$elementMessage.error(res.msg);
              }
            })
            .catch((err) => {
              _self.submitLoading = false;
            });
        }
      });
    },
    handleChange(value) {
      console.log(value);
      if (value && value.length > 0) {
        this.getStreetAction();
      }
    },
    streetChangeOn(value) {
      console.log(value);
    },
    getAddressListAction() {
      let data = {};
      let that = this;
      this.$HTTP
        .httpAppKey({
          url: "/shouwei/area/getAreaOpenStatusAllCityListV2",
          data: data,
          method: "post",
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            that.options = res.data;
          }
        })
        .catch((err) => { });
    },
    getStreetAction() {
      let that = this;
      let address = this.ruleForm.locCity.join(" ");
      this.$HTTP
        .httpToken({
          url: "/shouwei/area/getCountryAndTownsDataListByAreaCode",
          data: { address: address, type: 1 },
          method: "post",
        })
        .then((res) => {
          if (res.code == 0) {
            that.streets = res.data;
          }
        })
        .catch((error) => { });
    },
    sendCode() {
      var _self = this;
      this.$refs["ruleForm"].validateField(["phone"], (err) => {
        if (err && err.length != 0) {
          return;
        } else {
          _self.sendCodeLoading = true;
          let data = {
            mobile: _self.ruleForm.phone,
          };
          _self.$HTTP
            .httpAppKey({
              url: "/user/getCode",
              data: data,
              method: "post",
            })
            .then((res) => {
              console.log(1231313113131000000);
              _self.sendCodeLoading = false;
              if (res.code == 0) {
                _self.sendCodeStatus = true;
                let timer = setInterval(() => {
                  _self.sendCodeTime--;
                  if (_self.sendCodeTime == 0) {
                    clearInterval(timer);
                    _self.sendCodeStatus = false;
                    _self.sendCodeTime = 60;
                  }
                }, 1000);
              } else {
                _self.$elementMessage.error("获取验证码失败！");
              }
            })
            .catch((err) => {
              console.log(1231313113131);
              _self.sendCodeLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.bg-class {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--from {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.login-title {
  font-size: 26px;
  color: #101010;
  font-weight: bold;
  margin-top: 40px;
}

.submitButton {
  width: 60%;
  margin-left: 35px;
  margin-top: 40px;
}

.absolute-bottom {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
}
</style>
