<template>
  <div>
    <a-row type="flex" justify="center" style="margin-top: -200px">
      <a-col :span="23" :lg="16">
        <a-row style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          " :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }">
          <a-col :span="24">
            <div style="display: flex; align-items: center">
              <div style="
                  flex: 1;
                  text-align: left;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  margin-bottom: 20px;
                ">
                法治比赛
              </div>
              <div style="
                  flex: none;
                  text-align: left;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  margin-bottom: 20px;
                  cursor: pointer;
                " @click="answerRecord">
                答题记录
              </div>
            </div>
          </a-col>
          <a-col :span="24" style="margin-bottom: 20px">
            <div style="
                border-radius: 12px;
                min-height: 450px;
              ">
              <div style="
                  background: #4917c7;
                  min-height: 450px;
                  border-radius: 12px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <img src="/images/learn-legal.png" alt="" style="height: 80%; width: 85%; border-radius: 7px" />
                <button @click="learnCompetition()" style="
                    width: 120px;
                    height: 50px;
                    border-radius: 12px;
                    border: 1px solid #fff;
                    background: #4917c7;
                    color: #fff;
                    cursor: pointer;
                    font-weight: bold;
                  ">
                  学法竞赛
                </button>
              </div>
            </div>
          </a-col>
          <a-col :span="24">
            <div class='challenge-bg'>
              <div style="
                  position: relative;
                  border-radius: 12px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  flex-direction: column;
                ">
                <h3 style="color: #fff; font-size: 28px; font-weight: bold;margin-top: 20px;">
                  挑战答题
                </h3>
                <span style="color: #fff">答错两题离场，答对继续</span>
                <!-- <img src="/images/challenge-test2.png" alt="" style="height: 100%; width: 100%; border-radius: 12px" /> -->
                <button style="
                    width: 120px;
                    height: 50px;
                    border-radius: 12px;
                    border: 1px solid #fff;
                    background: #fff;
                    color: #000;
                    cursor: pointer;
                    font-weight: bold;
                    box-shadow: 2px 2px 6px 2px #403fc0;
                    position: absolute;right: 3%;top: 20px;
                  " @click="challengeAnswer()">
                  挑战答题
                </button>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  mounted() {
    // if (!this.$store.getters.isLogin) {
    //   this.$elementConfirm("您当前未登录，请先登录！", "系统提示", {
    //     distinguishCancelAndClose: true,
    //     closeOnClickModal: false,
    //     closeOnPressEscape: false,
    //     closeOnHashChange: true,
    //     confirmButtonText: "去登录",
    //     cancelButtonText: "取消",
    //   })
    //     .then(() => {
    //       this.$router.push("/login");
    //     })
    //     .catch((action) => {
    //       this.$router.back();
    //     });
    // }
    // this.$bus.$on("loginEvent", (isLogin) => {
    //   if (!isLogin) {
    //     this.$elementConfirm("您当前未登录，请先登录！", "系统提示", {
    //       distinguishCancelAndClose: true,
    //       closeOnClickModal: false,
    //       closeOnPressEscape: false,
    //       closeOnHashChange: true,
    //       confirmButtonText: "去登录",
    //       cancelButtonText: "取消",
    //     })
    //       .then(() => {
    //         this.$router.push("/login");
    //       })
    //       .catch((action) => {
    //         this.$router.back();
    //       });
    //   }
    // });
  },
  created() { },
  methods: {
    // 点击挑战答题
    challengeAnswer() {
      this.$router.push("/legalcompetition/challengeanswer");
    },
    // 点击学法竞赛
    learnCompetition() {
      this.$router.push("/legalcompetition/learncompetition");
    },
    // 点击跳往答题记录
    answerRecord() {
      this.$router.push("/legalcompetition/answerrecord");
    },
  },
};
</script>

<style lang="scss" scoped>
.challenge-bg {
  background: url('../../../public/images/challenge-test2.png') no-repeat center center;
  background-size: cover;
  border-radius: 12px;
  min-height: 450px;
}
</style>