<template>
  <div class="home" style="background-color: #ffffff; padding-bottom: 50px">
    <div class="banner-img">
      <img src="/images/cloud-base-banner.png" width="100%" height="auto" />
    </div>
    <div
      style="position: relative; z-index: 1"
      :style="{ marginTop: fullWidth > 992 ? '-160px' : '0' }"
    >
      <keep-alive>
        <component :is="componentName" @switchComponent="switchComponent"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import BaseList from "../components/cloud-base/cloud-base-list.vue";
export default {
  components: {
    BaseList,
  },
  data() {
    return {
      componentName: "BaseList",
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  methods: {
    ...mapMutations([]),
    switchComponent(name) {
      this.componentName = name;
    },
  },
};
</script>

<style>
.banner-img {
  overflow: hidden;
  position: relative;
}

.introduce {
  padding: 29px 0 40px 0;
  background-color: #ffffff;
}

.introduce-title {
  font-size: 30px;
  color: #464646;
  font-weight: bold;
}

.introduce-text {
  font-size: 14px;
  color: #0e9bd2;
  font-weight: 400;
}

.introduce-section {
  margin-top: 39px;
}

.report {
  margin-top: 24px;
}

.report-title {
  font-size: 18px;
  color: #101010;
  font-weight: bold;
}

.report-text {
  font-size: 14px;
  color: #686868;
  font-weight: 400;
  text-align: left;
}

.device {
  background-color: #ffffff;
  padding: 50px 0;
}

.item-dot {
  width: 11px;
  height: 11px;
  background-color: #0e9bd2;
  border-radius: 4px;
}

.form-header {
  background-color: #e3e3e3;
  font-size: 18px;
  color: #717171;
  font-weight: bold;
  padding: 10px 0;
  border-radius: 8px 8px 0 0;
}

.form-content {
  background-color: #ffffff;
}

.form-menu {
  font-size: 16px;
  color: #101010;
  font-weight: 400;
  /* text-align: left; */
}

.form-menu-active {
  /* display: inline-block; */
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  /* text-align: left; */
  position: relative;
}

.form-title-font {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  text-align: left;
}

.form-value-font {
  font-size: 14px;
  color: #101010;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.form-menu-active::after {
  position: absolute;
  width: 50%;
  height: 6px;
  left: 0;
  right: 0;
  margin: auto;
}

.item-box-lg {
  padding: 20px 29px;
  border-bottom: 1px solid #eeeeee;
}

.item-box-xs {
  padding: 10px 19px;
  border-bottom: 1px solid #eeeeee;
}

.check-strip {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 40%;
  height: 6px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #0390d8, #51d2bd);
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #0e9bd2 !important;
}

.ant-tabs-ink-bar {
  background-color: #0e9bd2;
}

.tab-font {
  font-size: 14px;
  font-weight: bold;
}

.checked-service-item {
  background: linear-gradient(135deg, #51d2be, #0090d7);
  color: #ffffff;
}
</style>
