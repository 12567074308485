<template>
  <div class="home" style="background-color: #ffffff; padding-bottom: 50px">
    <div class="banner-img">
      <img
        src="/images/classroom.jpg"
        width="100%"
        style="min-height: 300px"
        height="auto"
      />
    </div>
    <div :style="{ marginTop: fullWidth > 800 ? '-160px' : '0' }">
      <keep-alive>
        <!--				<component :is="componentName"></component>-->
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations } from "vuex";
import CustomTabs from "../components/custom-tabs/custom-tabs.vue";
import CourseList from "../components/classroom-list/classroom-list.vue";
import ClassroomCard from "../components/classroom-list/classroom-card.vue";
export default {
  name: "Home",
  components: {
    // CustomTabs,
    // CourseList,
    // ClassroomCard
  },
  data() {
    return {
      componentName: "ClassroomCard",
      submitObject: "single",
      reporter: "",
      reporterPhone: "",
      victim: "",
      victimPhone: "",
      reportStatus: "no",
      situation: "unknown",
      activeSection: "all",
      sortWay: "date", // date 时间排序 moods 人气排序
      sectionTabList: [
        {
          key: 1,
          value: "all",
          name: "全部",
        },
        {
          key: 2,
          value: 1,
          name: "教育局",
        },
        {
          key: 3,
          value: 2,
          name: "民政部",
        },
        {
          key: 4,
          value: 3,
          name: "司法",
        },
        {
          key: 5,
          value: 4,
          name: "检察院",
        },
        {
          key: 6,
          value: 5,
          name: "团委",
        },
        {
          key: 7,
          value: 6,
          name: "妇联",
        },
        {
          key: 8,
          value: 7,
          name: "公安",
        },
        {
          key: 9,
          value: 8,
          name: "卫健委",
        },
      ],
      rotate: 0,
      typeListStatus: 0, // 展开收起课程列表
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  methods: {
    ...mapMutations([]),

    /**
     * 选择部门
     */
    sectionChange() {},

    /**
     * 展开收起课堂类型
     */
    listSwitch() {
      if (this.typeListStatus == 0) {
        this.rotate = 180;
        this.typeListStatus = 1;
      } else {
        this.rotate = 0;
        this.typeListStatus = 0;
      }
    },

    /**
     * 搜索
     */
    onSearch(data) {
      console.log(data);
    },

    /**
     * 发起直播
     */
    sponsorLive() {
      this.$router.push("/live-demo");
    },
  },
};
</script>

<style>
.banner-img {
  overflow: hidden;
  position: relative;
}
</style>
