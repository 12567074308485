<template>
  <div class="home" style="background-color: #ffffff; padding-bottom: 50px">
    <div class="banner-img" :style="{
      width: fullWidth > 450 ? '100%' : 'auto',
      height: fullWidth > 450 ? 'auto' : '176px',
    }">
      <img src="images/report-banner.png" :width="fullWidth > 450 ? '100%' : 'auto'"
        :height="fullWidth > 450 ? 'auto' : '176px'" :style="{
          transform: fullWidth > 450 ? 'translate(0,0)' : 'translate(-10%,0)',
        }" />
    </div>
    <div style="margin-top: -160px">
      <a-row type="flex" justify="center">
        <a-col :span="23" :lg="16" style="box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20); border-radius: 8px">
          <div class="form-header">强制报告</div>
          <div class="form-content">
            <a-row class="item-box-lg">
              <a-col :span="12" :lg="4">
                <div class="form-menu-active">申请图文报告</div>
                <div class="check-strip"></div>
              </a-col>
            </a-row>
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="12" :lg="4">
                <div class="form-title-font">
                  报告主体
                  <span style="color: red">*</span>
                </div>
              </a-col>
              <a-col :span="12" :lg="20">
                <div class="zt-row">
                  <el-select v-model="reportSubjectValue" placeholder="请选择报告主体" @change="subjectChange" :clearable="true">
                    <el-option v-for="item in section" :key="item.id" :label="item.name" :value="item.id">
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                </div>
              </a-col>
            </a-row>
            <div v-if="showSubject">
              <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-col :span="8" :lg="4">
                  <div class="form-title-font">
                    报告人
                    <span style="color: red">*</span>
                  </div>
                </a-col>
                <a-col :span="16" :lg="20">
                  <a-input v-model="formData.name" class="form-value-font" style="border: none" placeholder="请输入报告人姓名" />
                </a-col>
              </a-row>
              <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-col :span="8" :lg="4">
                  <div class="form-title-font">
                    联系方式
                    <span style="color: red">*</span>
                  </div>
                </a-col>
                <a-col :span="16" :lg="20">
                  <a-input v-model="formData.phone" class="form-value-font" style="border: none" placeholder="请输入报告人电话" />
                </a-col>
              </a-row>
              <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-col :span="8" :lg="4">
                  <div class="form-title-font">所在单位</div>
                </a-col>
                <a-col :span="16" :lg="20">
                  <a-input v-model="formData.depet" class="form-value-font" style="border: none"
                    placeholder="请输入报告人所在单位" />
                </a-col>
              </a-row>
              <div style="width: 100%; background-color: #f9f9f9; height: 20px"></div>
            </div>
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">
                  报告情形
                  <span style="color: red">*</span>
                </div>
              </a-col>
              <a-col :span="16" :lg="20" style="text-align: right">
                <div style="display: flex; align-items: center">
                  <div class="form-value-font" style="flex: 1; margin-right: 10px; color: #626262; line-height: 32px"
                    @click="showTypeAction">
                    {{
                      checkedType.id === undefined ? "请选择报告情形" : checkedType.name
                    }}
                  </div>
                  <a-icon type="right" />
                </div>
              </a-col>
            </a-row>
            <!-- 案发地区 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">
                  案发地区
                  <span style="color: red">*</span>
                </div>
              </a-col>
              <a-col :span="16" :lg="20" style="text-align: right">
                <el-cascader v-model="address" :options="items" :props="{ expandTrigger: 'hover' }" @change="handleChange"
                  ref="deptList"></el-cascader>
              </a-col>
            </a-row>
            <div v-if="address.length != 0 ? true : false">
              <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-col :span="8" :lg="4">
                  <div class="form-title-font">案发街道/乡镇</div>
                </a-col>
                <a-col :span="16" :lg="20" style="text-align: right">
                  <el-cascader v-model="street" :clearable="true" :options="streets"
                    :props="{ expandTrigger: 'hover', checkStrictly: false }" @change="handleStreetChange"></el-cascader>
                </a-col>
              </a-row>
              <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-col :span="8" :lg="4">
                  <div class="form-title-font">详细地址</div>
                </a-col>
                <a-col :span="16" :lg="20">
                  <a-input v-model="detailAddress" class="form-value-font" style="border: none" placeholder="请输入详细地址" />
                </a-col>
              </a-row>
              <div style="width: 100%; background-color: #f9f9f9; height: 20px"></div>
            </div>
            <!-- //上报单位 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="12" :lg="4">
                <div class="form-title-font">
                  接收报告单位
                  <span style="color: red">*</span>
                </div>
              </a-col>
              <a-col :span="12" :lg="20" style="text-align: right">
                <!-- <a-input
                  v-model="upReportdepetName"
                  class="form-value-font"
                  style="border: none"
                  placeholder=""
                /> -->
                <el-cascader filterable :disabled="value ? false : true" placeholder="搜索" v-model="value"
                  :options="options" :props="{ expandTrigger: 'hover' }" @change="handleDeptChange"></el-cascader>
              </a-col>
            </a-row>
            <!-- 抄送单位 -->
            <!-- <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="12" :lg="4">
                <div class="form-title-font">抄送单位</div>
              </a-col>
              <a-col :span="12" :lg="20">
                <div class="zt-row">
                  <el-select
                    :multiple="true"
                    v-model="chaosongReportdepetValue"
                    placeholder="请选择抄送单位(非必选)"
                    @change="chaosongDeptAction"
                    :clearable="true"
                  >
                    <el-option
                      v-for="item in defaultChaosongDepts"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                </div>
              </a-col>
            </a-row> -->
            <div>
              <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-col :span="8" :lg="4">
                  <div class="form-title-font">受害人</div>
                </a-col>
                <a-col :span="16" :lg="20">
                  <a-input v-model="formData.victim" class="form-value-font" style="border: none"
                    placeholder="请输入受害人姓名" />
                </a-col>
              </a-row>
              <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-col :span="8" :lg="4">
                  <div class="form-title-font">联系方式</div>
                </a-col>
                <a-col :span="16" :lg="20">
                  <a-input v-model="formData.victimTel" class="form-value-font" style="border: none"
                    placeholder="请输入受害人电话" />
                </a-col>
              </a-row>
              <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
                <a-col :span="8" :lg="4">
                  <div class="form-title-font">身份证号</div>
                </a-col>
                <a-col :span="16" :lg="20">
                  <a-input v-model="formData.victimIDNo" class="form-value-font" style="border: none"
                    placeholder="请输入受害人身份证号码" />
                </a-col>
              </a-row>
              <div style="width: 100%; background-color: #f9f9f9; height: 20px"></div>
            </div>

            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="24" :lg="24">
                <div class="form-title-font">
                  报告说明<span style="color: red">*</span>
                </div>
              </a-col>
              <a-col :span="24" :lg="24" style="margin-top: 16px">
                <a-textarea v-model="formData.detail" placeholder="请详细描述报告情况" :auto-size="{ minRows: 3 }"
                  style="border: none; background-color: #f4f4f4; padding: 10px 20px" />
              </a-col>
              <a-col :span="24" :lg="24">
                <div class="media-content">
                  <div class="form-title-font" style="margin: 20px 0px">
                    上传图片/视频
                  </div>
                  <!-- 只能上傳圖片 -->
                  <!-- <el-upload
                    action="https://fileprod.skillhub.top/file/imageFileUpload/"
                    list-type="picture-card"
                    :multiple="true"
                    :on-success="fileSuccessAction"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload> -->

                  <el-upload action="https://fileprod.skillhub.top/file/batchFileUploadImgVideo" :limit="9"
                    :accept="'image/*,video/*'" :before-upload="beforeuploadMainGraph"
                    :on-success="handleSuccessMainGraph" :on-preview="handlePreviewMainGraph"
                    :before-remove="beforeremoveMainGraph" :on-remove="handleRemoveMainGraph" list-type="picture-card"
                    :file-list="fileList" :multiple="true">
                    <i class="el-icon-plus spc1"></i>
                  </el-upload>
                  <!-- 图片放大时候用的 -->
                  <el-dialog :visible.sync="dialogVisibleMainGraph" class="spc12">
                    <img width="100%" :src="imageUrl" alt="" />
                  </el-dialog>
                  <!-- 視頻放大时候用的 -->
                  <el-dialog :visible.sync="dialogVisibleMainGraphVideo" class="spc12">
                    <video :src="videoPath" controls></video>
                  </el-dialog>
                </div>
              </a-col>
            </a-row>
            <div style="width: 300px; margin: 150px auto 0 auto; padding-bottom: 52px">
              <a-button style="
                    border-radius: 25px;
                    background: linear-gradient(90deg, #51d2be, #0090d7);
                    font-size: 15px;
                    color: #fefefe;
                    font-weight: bold;
                    height: 50px;
                    width: 340px;
                    border: none;
                  " @click="submitReport">提交报告
              </a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <el-dialog title="选择报告情形" :show-close="true" :visible.sync="dialog" width="50%">
      <div class="demo-drawer__content">
        <ul style="overflow: auto">
          <div class="type-default-box" :class="checkedType.id == item.id ? 'type-checked-box' : ''"
            v-for="(item, index) in reportTypeList" :key="index" @click="checkTypeAction(item)">
            {{ item.name }}
          </div>
        </ul>
        <span slot="footer" class="dialog-footer" style="margin-top: 30px">
          <el-button type="primary" round @click="dialog = false" style="width: 320px; height: 50px">确 定</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog title="提示" :show-close="false" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <!-- <div style="text-align: left; margin-bottom: 10px">
        一、特别提醒。本平台为强制报告线索接收推送平台，暂不能处理紧急事项，不能接收紧急事项线索报告，若遇紧急事项需要及时处理的，请立即拨打110或者联系有关部门处理。
      </div>
      <div style="text-align: left; margin-bottom: 10px">
        二、强制报告。是指国家机关、法律法规授权行使公权力的各类组织及法律规定的公职人员，密切接触未成年人行业的各类组织及其从业人员，在工作中发现未成年人遭受或者疑似遭受不法侵害以及面临不法侵害危险的，应当立即向公安机关、民政、教育等有关部门报告。
      </div>
      <div style="text-align: left; margin-bottom: 10px">
        三、密切接触未成年人行业的各类组织。是指依法对未成年人负有教育、看护、医疗、救助、监护等特殊职责，或者虽不负有特殊职责但具有密切接触未成年人条件的企事业单位、基层群众自治组织、社会组织。主要包括：居（村）民委员会；中小学校、幼儿园、校外培训机构、未成年人校外活动场所等教育机构及校车服务提供者；托儿所等托育服务机构；医院、妇幼保健院、急救中心、诊所等医疗机构；儿童福利机构、救助管理机构、未成年人救助保护机构、社会工作服务机构；旅店、宾馆等。
      </div>
      <div style="text-align: left; margin-bottom: 20px">
        四、工作中发现未成年人遭受或者疑似遭受不法侵害以及面临不法侵害危险的情况包括：
        <div style="text-align: left; margin-bottom: 5px">
          （一）未成年人的生殖器官或隐私部位遭受或疑似遭受非正常损伤的；
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （二）不满十四周岁的女性未成年人遭受或疑似遭受性侵害、怀孕、流产的；
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （三）十四周岁以上女性未成年人遭受或疑似遭受性侵害所致怀孕、流产的；
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （四）未成年人身体存在多处损伤、严重营养不良、意识不清，存在或疑似存在受到欺凌、虐待、殴打、家庭暴力或者被人麻醉等情形的；
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （五）未成年人因自杀、自残、工伤、中毒、被人麻醉、殴打等非正常原因导致伤残、死亡情形的；
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （六）未成年人被遗弃或长期处于无人照料状态的；
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （七）发现未成年人来源不明、失联、失踪或者被拐卖、收买的；
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （八）发现未成年人被组织乞讨的；
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （九）未成年人被采用暴力、限制人生自由等非法行为强迫婚嫁的；
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （十）发现未成年人与他人以夫妻名义共同生活、生育的;
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （十一）旅馆、宾馆、酒店等住宿经营者接待未成年人入住，发现有违法犯罪嫌疑的；
        </div>
        <div style="text-align: left; margin-bottom: 5px">
          （十二）其他严重侵害未成年人身心健康、侵犯未成年人合法权益的情形或未成年人正在面临不法侵害危险的。
        </div>
      </div>
      <div style="text-align: left; margin-bottom: 10px">
        五、正确报告。请正确选择管辖内对应的部门进行报告，不得随意选择无关部门报告。
      </div>
      <div style="text-align: left; margin-bottom: 10px">
        六、如实报告。报告人应当如实报告有关事项，不得虚假报告。对于虚假报告的，将严肃追究相关责任。
      </div> -->
      <div style="white-space:pre-wrap;text-align: left;">
        {{ reportContentText }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="dialogVisible = false" style="width: 320px">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const section = [
  {
    id: 1,
    icon: "/images/report-subject-icon/state-organs.png",
    name: "国家机关",
  },
  {
    id: 2,
    icon: "/images/report-subject-icon/village.png",
    name: "村居两委",
  },
  {
    id: 3,
    icon: "/images/report-subject-icon/hotel.png",
    name: "旅馆类从业者",
  },
  {
    id: 4,
    icon: "/images/report-subject-icon/hospital.png",
    name: "医疗类从业者",
  },
  {
    id: 5,
    icon: "/images/report-subject-icon/education.png",
    name: "教育类从业者",
  },
  {
    id: 6,
    icon: "/images/report-subject-icon/other.png",
    name: "其他行业从业者",
  },
];
const serveList = [
  "经济救助",
  "转学安置",
  "法律援助",
  "司法救助",
  "心理危机干预",
  "社会融入辅导",
  "家庭教育指导",
  "成立家长委员会",
  "医疗救治",
  "欺凌防控处置机制",
  "未成年矫正服务",
  "公益服务",
  "卫生保健服务",
  "未保热线",
  "残疾未成年教育",
  "早期识别和诊断治疗",
  "儿童之家",
  "残疾未成年服务",
];
// @ is an alias to /src
import { mapMutations, mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      upReportdepetName: "",
      upReportdepetValue: "",
      chaosongReportdepetValue: [],
      address: "",
      street: "",
      dialog: false,
      fileList: [],
      showSubject: false,
      reportSubjectValue: "",
      section: section,
      serveList: serveList,
      submitObject: "single",
      reporter: "",
      reporterPhone: "",
      victim: "",
      victimPhone: "",
      reportStatus: "no",
      situation: "unknown",
      detailAddress: "",
      count: 0,
      dialogVisible: true,
      formData: {
        role: "personal", // personal 个人，group 组织
        name: "",
        phone: "",
        depet: "",

        victim: "",
        victimTel: "",
        victimIDNo: "",
        detail: "",
      },
      reportTypeList: [],
      checkedType: {},
      items: [],
      streets: [],
      cityCode: "",
      uploadDepts: [],
      defaultChaosongDepts: [],
      streetSelect: [],
      uploadDept: {},
      chaosongDept: {},

      dialogVisibleMainGraph: false,
      dialogVisibleMainGraphVideo: false,
      imageUrlList: [],
      imageUrl: "",
      videoPath: "",

      policesList: [],

      // 报告单位
      value: "",
      options: [],
      policesDeptId: "",

      reportContentText: '',
    };
  },
  // streetSelect当前选择的街道
  computed: {
    ...mapState(["fullWidth"]),
  },
  mounted() {
    console.log("Report------mounted");
    this.getReportContentText();
    this.getUserAction();
    this.getReportType();
    this.getOpenAreaction();
    this.getchaosongDeptAction();
  },
  methods: {
    ...mapMutations([]),

    // 获取强制报告须知内容
    getReportContentText() {
      this.$HTTP
        .httpToken({
          url: "/shouwei/report/getReportNotice",
          data: {},
          method: "post",
        })
        .then((res) => {
          if (res.code === 0) {
            this.reportContentText = res.data.content;
          }
        })
        .catch((error) => { });
    },
    handleDialogClose() {
      this.dialogVisible = false;
      this.count = 0;
    },
    submitReport() {
      if (this.reportSubjectValue == "") {
        this.$elementMessage.error("请选择报告主体");
        return;
      }
      if (this.formData.name == "") {
        this.$elementMessage.error("请填写报告人名字");
        return;
      }
      if (this.formData.phone == "") {
        this.$elementMessage.error("请填写报告人电话");
        return;
      }
      if (this.cityCode == "") {
        this.$elementMessage.error("请选择案发地区");
        return;
      }
      if (this.formData.detail == "") {
        this.$elementMessage.error("请填写报告说明");
        return;
      }
      let province = this.$refs.deptList.getCheckedNodes()[0].pathLabels[0];
      let city = this.$refs.deptList.getCheckedNodes()[0].pathLabels[1];
      let district = this.$refs.deptList.getCheckedNodes()[0].pathLabels[2];

      let annexList = [];
      if (this.fileList.length > 0) {
        this.fileList.forEach((v, i) => {
          if (v.response.data[0].indexOf(".mp4") != -1) {
            let obj = {
              name: "",
              url: v.response.data[0],
              type: 3,
              businessType: 3,
            };
            annexList.push(obj);
          } else {
            let obj = {
              name: "",
              url: v.response.data[0],
              type: 1,
              businessType: 3,
            };
            annexList.push(obj);
          }
        });
      }
      let town = "";
      let country = "";
      if (this.streetSelect.length != 0) {
        town = this.streetSelect[0];
        if (town == "直辖") {
          town = "";
        }
        if (this.streetSelect.length > 1) {
          country = this.streetSelect[1];
          if (country == "直辖") {
            country = "";
          }
        }
      }

      let data = {
        reportRecord: {
          name: this.formData.name,
          tel: this.formData.phone,
          victim: this.formData.victim,
          victimTel: this.formData.victimTel,
          victimIDNo: this.formData.victimIDNo,
          reportSubject: this.reportSubjectValue,

          town: town,
          country: country,

          province: province,
          city: city,
          district: district,
          cityCode: this.cityCode,
          reportType: 1,

          address: this.detailAddress,
          situation: this.checkedType.name, //报告类型
          details: this.formData.detail,
          ccDeptIds: this.chaosongReportdepetValue, //抄送部门id字符串数组
          annexList: annexList,
        },
        userUnit: this.formData.depet,
        reportUnitId: this.policesDeptId, //报告接收单位id
      };
      console.log(data);

      const loading = this.$loading.service({
        lock: true,
        text: "正在提交报告...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let _self = this;
      this.$HTTP
        .httpToken({
          url: "/shouwei/report/savaReportRecord",
          data: data,
          method: "post",
        })
        .then((res) => {
          loading.close();
          if (res.code == 0) {
            this.$elementMessage({
              showClose: true,
              message: "提交报告成功",
              type: "success",
            });
            this.$router.push({ name: "MyReport", path: "/myreport" });
          } else {
            this.$elementMessage.error(res.msg);
          }
        })
        .catch((error) => {
          loading.close();
          this.$elementMessage.error(error);
        });
      console.log(data);
    },
    uploadDeptAction(e) {
      console.log(e);
    },
    chaosongDeptAction(e) {
      console.log(e);
    },
    getUploadDeptAction() {
      let town = "";
      let country = "";
      if (this.streetSelect.length != 0) {
        town = this.streetSelect[0];
        if (town == "直辖") {
          town = "";
        }
        if (this.streetSelect.length > 1) {
          country = this.streetSelect[1];
          if (country == "直辖") {
            country = "";
          }
        }
      }
      let data = {
        cityCode: this.cityCode,
        townsName: town,
        countryName: country,
      };
      let _self = this;
      this.$HTTP
        .httpToken({
          url: "/shouwei/department/getListByCityCode",
          data: data,
          method: "post",
        })
        .then((res) => {
          this.requestUploading = false;
          if (res.code == 0) {
            console.log("cityCode", res.data);
            this.uploadDepts = res.data;
            this.value = [];
            this.options = [];
            if (this.uploadDepts.length > 0) {
              this.uploadDept = this.uploadDepts[0];
              this.upReportdepetName = this.uploadDept.name;
              this.upReportdepetValue = this.uploadDept.id;

              let policesList = this.uploadDepts.map((v) => {
                return {
                  value: v.name,
                  label: v.name,
                  id: v.id,
                };
              });
              this.options = policesList;
              this.value = this.options[0].value;
              this.policesDeptId = this.options[0].id;
              console.log("this.options", this.options);
              console.log("this.value", this.value);
            }
          }
        })
        .catch((error) => { });
    },
    getchaosongDeptAction() {
      let data = {};
      let _self = this;
      this.$HTTP
        .httpToken({
          url: "/shouwei/department/findDefaultList",
          data: data,
          method: "post",
        })
        .then((res) => {
          this.requestChaosonging = false;
          if (res.code == 0) {
            this.defaultChaosongDepts = res.data;
            console.log("获取到抄送单位" + JSON.stringify(this.defaultChaosongDepts));
          }
        })
        .catch((error) => { });
    },
    // 省市区选择
    handleChange(value) {
      this.streets = [];
      this.street = "";
      this.uploadDepts = [];
      this.streetSelect = [];
      this.upReportdepetValue = "";
      this.chaosongReportdepetValue = [];
      if (value.length == 3) {
        this.cityCode = value[2];
        this.getStreetAction();
      }
      console.log(value);
      console.log("城市code");
      console.log(this.cityCode);
      this.getUploadDeptAction();
    },
    // 街道选择
    handleStreetChange(value) {
      this.streetSelect = value;
      console.log("街道", value);

      this.uploadDepts = [];
      this.upReportdepetValue = "";
      this.chaosongReportdepetValue = [];
      this.getUploadDeptAction();
    },
    // 接收报告单位
    handleDeptChange(value) {
      this.options.forEach((v, i) => {
        if (v.value == value) {
          this.policesDeptId = this.options[i].id;
        }
      });
    },
    checkTypeAction(data) {
      console.log(data);
      console.log(data.name);
      this.checkedType = data;
      console.log(this.checkedType);
      // this.dialog = false;
      this.count = 0;
    },
    // 只能上传图片
    // fileSuccessAction(response, file, fileList) {
    //   this.fileList = fileList;
    //   console.log("上传文件");
    //   console.log(fileList);
    // },
    // 选择报告主体
    subjectChange(e) {
      console.log(e);
      if (e === "") {
        this.showSubject = false;
      } else {
        this.showSubject = true;
      }
    },
    //获取报告类型
    getReportType() {
      let data = {};
      this.$HTTP
        .httpToken({
          url: "/shouwei/report/getReportSituationList",
          data: {},
          method: "post",
        })
        .then((res) => {
          if (res.code == 0) {
            this.reportTypeList = res.data.reportSituationList;
          } else {
            this.$elementMessage.error("获取报告情形失败");
          }
        });
    },
    reportHistoryChange() { },
    showTypeAction() {
      this.dialog = true;
    },
    // 获取用户单位
    getUserAction() {
      let _self = this;
      this.$HTTP
        .httpToken({
          url: "/shouwei/report/reporterInfo",
          data: {},
          method: "post",
        })
        .then((res) => {
          if (res.code == 0) {
            _self.formData.name = res.data.name;
            _self.formData.phone = res.data.tel;
            _self.formData.depet = res.data.userUnit;
          }
        })
        .catch((err) => {
          _self.sendCodeLoading = false;
        });
    },
    //获取开通省市区
    getOpenAreaction() {
      let _self = this;
      this.$HTTP
        .httpToken({
          url: "/shouwei/area/getAreaOpenStatusDataList",
          data: {},
          method: "post",
        })
        .then((res) => {
          if (res.code == 0) {
            this.items = res.data.map(function (item, index) {
              var provice = {};
              provice["label"] = item.name;
              provice["value"] = item.cityCode;
              provice["children"] = item.children.map(function (city_item, index) {
                var city = {};
                if (item.name === "北京市") {
                  city["value"] = 32678432;
                } else {
                  city["value"] = city_item.cityCode;
                }
                city["label"] = city_item.name;
                console.log(city_item.name);

                city["children"] = city_item.children.map(function (area_item, index) {
                  var area = {};
                  area["label"] = area_item.name;
                  area["value"] = area_item.cityCode;
                  return area;
                });
                return city;
              });
              return provice;
            }, this);
          } else {
            _self.$elementMessage.error("获取信息失败");
          }
        })
        .catch((err) => {
          _self.sendCodeLoading = false;
        });
    },
    getStreetAction() {
      this.$HTTP
        .httpToken({
          url: "/shouwei/area/getCountryAndTownsDataListByAreaCode",
          data: { areaCode: this.cityCode },
          method: "post",
        })
        .then((res) => {
          if (res.code == 0) {
            if (res.data.length != 0) {
              let datas = res.data[0].childrenList;
              this.streets = datas.map(function (item, index) {
                let provice = {};
                provice["label"] = item.townName;
                provice["value"] = item.townName;

                if (item.childrenList && item.childrenList.length != 0) {
                  provice["children"] = item.childrenList.map(function (
                    city_item,
                    index
                  ) {
                    let city = {};
                    city["value"] = city_item.countryName;
                    city["label"] = city_item.countryName;
                    return city;
                  });
                }
                return provice;
              }, this);
            }
          }
        })
        .catch((error) => { });
    },
    handleClose(done) {
      this.dialog = false;
      this.count = 0;
    },
    cancelForm() {
      this.dialog = false;
      this.count = 0;
    },

    // el-upload方法占位
    beforeuploadMainGraph(file) {
      console.log(file);
      let _this = this;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        let newUrl = reader.result; //图片路径
        console.log(newUrl); //这个就是base64格式的了
      };
    },
    //上传成功
    handleSuccessMainGraph(response, file, fileList) {
      console.log(file);
      this.fileList = fileList;
      this.imageUrl = response.data[0];
      if (this.imageUrl && this.imageUrl.indexOf(".mp4") != -1) {
        let path =
          "https://fileprod.skillhub.top/file/getVideoFileCoverBySizeV3?videoFileUrl=" +
          this.imageUrl;
        console.log("视频地址:" + path);
        this.videoPath = this.imageUrl;
        this.imageUrl = path;
        file.url = this.imageUrl;
        console.log("最后", this.imageUrl);
      }
    },
    //点击文件列表中已上传的文件
    handlePreviewMainGraph(file) {
      console.log("111", file);
      if (file.response.data[0].indexOf(".mp4") != -1) {
        this.dialogVisibleMainGraphVideo = true;
      } else {
        this.imageUrl = file.url;
        this.dialogVisibleMainGraph = true;
      }
    },
    //删除文件前
    beforeremoveMainGraph(file, fileList) { },
    //删除文件时
    handleRemoveMainGraph(file, fileList) {
      this.imageUrlList = fileList;
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.type-default-box {
  padding: 26px 38px;
  background-color: #f6f8f9;
  border-radius: 16px;
  margin-top: 28px;
  margin-right: 40px;
  font-size: 28px;
  color: #333333;
  font-weight: 400;
  margin-top: 20px;
  font-size: 20px;
}

.type-checked-box {
  background: linear-gradient(90deg, #7bc5e0, #6babd9);
  color: #ffffff;
  font-weight: bold;
  margin-top: 20px;
}

.type-default-box:first-child {
  margin-top: 0;
}

.banner-img {
  overflow: hidden;
  position: relative;
}

.introduce {
  padding: 29px 0 40px 0;
  background-color: #ffffff;
}

.demo-drawer__content {
  margin-bottom: 80px;
}

.introduce-title {
  font-size: 30px;
  color: #464646;
  font-weight: bold;
}

.media-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.introduce-text {
  font-size: 14px;
  color: #0e9bd2;
  font-weight: 400;
}

.introduce-section {
  margin-top: 39px;
}

.report {
  margin-top: 24px;
}

.report-title {
  font-size: 18px;
  color: #101010;
  font-weight: bold;
}

.report-text {
  font-size: 14px;
  color: #686868;
  font-weight: 400;
  text-align: left;
}

.device {
  background-color: #ffffff;
  padding: 50px 0;
}

.item-dot {
  width: 11px;
  height: 11px;
  background-color: #0e9bd2;
  border-radius: 4px;
}

.zt-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.form-header {
  background-color: #e3e3e3;
  font-size: 18px;
  color: #717171;
  font-weight: bold;
  padding: 10px 0;
  border-radius: 8px 8px 0 0;
}

.form-content {
  background-color: #ffffff;
}

.form-menu {
  font-size: 16px;
  color: #101010;
  font-weight: 400;
  /* text-align: left; */
}

.form-menu-active {
  /* display: inline-block; */
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  /* text-align: left; */
  position: relative;
}

.form-title-font {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  text-align: left;
}

.form-value-font {
  font-size: 14px;
  color: #101010;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.form-menu-active::after {
  position: absolute;
  width: 50%;
  height: 6px;
  left: 0;
  right: 0;
  margin: auto;
}

.item-box-lg {
  padding: 20px 29px;
  border-bottom: 1px solid #eeeeee;
}

.item-box-xs {
  padding: 10px 19px;
  border-bottom: 1px solid #eeeeee;
}

.check-strip {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 40%;
  height: 6px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #0390d8, #51d2bd);
}

.el-input {
  border: none !important;
}

.el-select {
  border: none !important;
}

input {
  border: none !important;
  text-align: right;
}

input::-webkit-input-placeholder {
  color: #626262 !important;
  font-size: 14px;
}

/* 视频上传样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.video-avatar {
  width: 400px;
  height: 200px;
}

.images-info {
  height: 100px;
  width: 100px;
}
</style>
