<template>
  <div class="my-order-list">
    <a-row
      class="content"
      v-loading="loading"
      :gutter="24"
      style="margin-top: 20px; width: 100%; margin-left: 0"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.2)"
    >
      <div style="width: 100%; display: flex; justify-content: center">
        <template v-if="MyOrderList.length > 0 ? false : true">
          <a-row
            type="flex"
            justify="center"
            style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
          >
            <el-empty :description="description"></el-empty>
          </a-row>
        </template>
      </div>
      <el-card
        class="box-card"
        v-for="(item, index) in MyOrderList"
        :key="index"
      >
        <div
          slot="header"
          style="display: flex; justify-content: space-between"
        >
          <span>{{ item.baseName }}</span>
          <el-button
            style="float: right; padding: 3px 0; color: black"
            type="text"
            >{{
              item.status === -1
                ? "取消预约"
                : item.status === 0
                ? "预约中"
                : item.status === 1
                ? "预约成功"
                : item.status === 2
                ? "预约失败"
                : "预约结束"
            }}</el-button
          >
        </div>
        <el-form ref="form" label-width="100px" label-position="left">
          <el-form-item label="预约人">
            <span>{{ item.userName }}</span>
          </el-form-item>
          <el-form-item label="预约时间">
            <span>{{ item.visitTime }}</span>
          </el-form-item>
          <el-form-item label="参观人数">
            <span>{{ item.visitCount }}人</span>
          </el-form-item>
          <el-form-item label="预约留言" style="height: 40px">
            <span class="message">{{ item.leavingMessage }}</span>
          </el-form-item>
          <el-form-item label="基地联系人">
            <span>{{ item.contact }}</span>
          </el-form-item>
          <el-form-item label="基地电话">
            <span>{{ item.contactMobile }}</span>
          </el-form-item>
          <el-form-item label="失败原因" v-if="item.status == 2">
            <span>{{ item.rejection }}</span>
          </el-form-item>
        </el-form>
        <el-button
          type="goon"
          class="btn"
          @click="handleDialog(item.id, index)"
          v-if="item.status === 0"
          >取消预约
        </el-button>
      </el-card>
    </a-row>
    <!-- 取消弹出框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span style="font-size: 18px; font-weight: bold">是否确认取消预约？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="cancelMyOrder(id, index)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      MyOrderList: [],
      empty: true,
      loading: false,
      description: "暂无数据",
      dialogVisible: false,
      id: "",
      index: -1,
    };
  },
  created() {
    this.getMyOrderList();
  },
  methods: {
    // 获取我的预约列表
    getMyOrderList() {
      this.loading = true;
      this.$HTTP
        .httpToken({
          url: "/shouwei/exhibition/hall/getMakeAppointmentList",
          method: "post",
          data: {},
        })
        .then((res) => {
          this.loading = false;
          //   loading.close();
          console.log("111", res);
          if (res.code === 0) {
            this.MyOrderList = [...res.data.makeAppointmentList];
            this.empty = false;
          } else {
            this.$elementMessage.error(res.msg);
          }
        })
        .catch((error) => {
          //   loading.close();
          this.loading = false;
          this.$elementMessage.error("您暂时还没有预约哦~");
        });
    },
    // 取消预约
    cancelMyOrder() {
      this.dialogVisible = false;
      this.$HTTP
        .httpToken({
          url: "/shouwei/exhibition/hall/cancelMakeAppointment",
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((res) => {
          //   loading.close();
          console.log("111", res);
          if (res.code == 0) {
            this.MyOrderList[this.index].status = -1;
            // this.getMyOrderList();
          } else {
            this.$elementMessage.error(res.msg);
          }
        })
        .catch((error) => {});
    },
    // 弹出取消对话框
    handleDialog(id, index) {
      this.dialogVisible = true;
      this.id = id;
      this.index = index;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}

// el-button颜色更改
.el-button--goon.is-active,
.el-button--goon:active {
  background: #37b6df;
  border-color: #37b6df;
  color: #fff;
}

.el-button--goon:focus,
.el-button--goon:hover {
  background: #37b9ff;
  border-color: #37b9ff;
  color: #fff;
}

.el-button--goon {
  color: #fff;
  background-color: #37b6df;
  border-color: #37b6df;
}

.el-form-item__content span {
  display: inline-block;
  width: 100%;
  text-align: left;
}

.box-card {
  width: 32%;
  margin-right: 2%;

  &:nth-child(3n + 2) {
    margin-right: 0;
  }
}

.content {
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  .box-card {
    margin-bottom: 20px;
  }
}

.el-card {
  position: relative;

  .el-form {
    padding-bottom: 20px;
  }
}

.btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

::v-deep .el-form-item {
  margin-bottom: 0;
}

.message {
  width: 100px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  margin: 0;
}
</style>