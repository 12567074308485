<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row style="
                              background-color: #ffffff;
                              box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
                              border-radius: 8px;
                            " :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }">
          <a-col :span="24">
            <div style="display: flex; align-items: center">
              <div style="
                                    flex: 1;
                                    text-align: left;
                                    font-size: 18px;
                                    color: #333333;
                                    font-weight: bold;
                                  ">
                云课堂
              </div>
              <div style="display: flex; align-items: center; cursor: pointer" @click="createClassRoom()">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14797" width="18"
                  height="18">
                  <path
                    d="M890.026667 145.066667l-11.093334-11.093334a21.333333 21.333333 0 0 0-14.933333-5.973333 20.053333 20.053333 0 0 0-7.253333 0L142.08 384a21.333333 21.333333 0 0 0-14.08 21.76v6.826667a21.76 21.76 0 0 0 12.373333 19.626666l306.773334 144.64 144.64 306.773334a21.76 21.76 0 0 0 19.626666 12.373333h6.826667a21.333333 21.333333 0 0 0 20.053333-14.08L896 167.253333a21.333333 21.333333 0 0 0-5.973333-22.186666z m-282.88 570.88L554.666667 602.453333a85.333333 85.333333 0 0 1 14.506666-94.293333l66.56-72.106667a21.333333 21.333333 0 0 0 0-29.44l-16.213333-16.213333a21.333333 21.333333 0 0 0-29.44 0l-72.106667 66.56a85.333333 85.333333 0 0 1-96.426666 12.373333L308.053333 416.853333l466.346667-167.253333z"
                    p-id="14798" fill="#0E9BD2"></path>
                </svg>
                <div style="
                                      font-size: 14px;
                                      color: #0e9bd2;
                                      font-weight: 400;
                                      margin-left: 3px;
                                    ">
                  发起
                </div>
              </div>
            </div>
          </a-col>
          <a-col :span="24" style="margin-top: 15px; text-align: left; min-height: 500px" v-loading="loading"
            element-loading-text="加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)">
            <template v-if="empty">
              <a-row type="flex" justify="center" style="margin-bottom: 10px; margin-top: 10px; min-height: 400px">
                <el-empty :description="description"></el-empty>
              </a-row>
            </template>
            <el-tabs v-model="activeName" v-else>
              <el-tab-pane :label="item.name" :name="item.name" v-for="item in sectionTabList" :key="item.name"
                :lazy="true">
                <a-row>
                  <a-col :span="24">
                    <CourseList :departmentId="item.id"></CourseList>
                  </a-col>
                </a-row>
              </el-tab-pane>
            </el-tabs>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapState } from "vuex";
// import CustomTabs from '../custom-tabs/custom-tabs.vue'
import CourseList from "./classroom-list.vue";

export default {
  name: "Home",
  components: {
    CourseList,
  },
  data() {
    return {
      activeName: "全部",
      loading: true,
      empty: true,
      sectionTabList: [{ id: "", name: "全部" }],
      description: "暂无数据",
      showMiniProPopup: false,
    };
  },
  computed: {
    ...mapState(["fullWidth", 'userInfo']),
  },
  mounted() {
    this.getTabList();
  },
  methods: {
    ...mapMutations([]),
    createClassRoom() {
      if (this.$store.getters.token && this.$store.getters.token.length != 0) {
        this.$router.push({
          path: "create",
        });
      } else {
        this.$elementMessage({
          showClose: false,
          message: '请先进行登录',
          type: "error",
        });
      }
    },
    getTabList() {
      this.loading = true;
      this.$HTTP
        .httpToken({
          url: "/shouwei/department/getClassroomDepartmentList",
          method: "post",
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.code == 0 && res.data && res.data.length > 0) {
            this.empty = false;
            this.sectionTabList = res.data;
            this.sectionTabList.splice(0, 0, { id: "", name: "全部" });
          } else {
            this.sectionTabList = [];
            this.empty = true;
            this.description = "暂无数据";
          }
        })
        .catch((e) => {
          this.sectionTabList = [];
          this.loading = false;
          this.empty = true;
          this.description = e;
        });
    },

    /**
     * 发起直播
     */
    sponsorLive() {
      this.$router.push("/live");
    },
  },
};
</script>

<style scoped lang="scss">
.introduce {
  padding: 29px 0 40px 0;
  background-color: #ffffff;
}

.introduce-title {
  font-size: 30px;
  color: #464646;
  font-weight: bold;
}

.introduce-text {
  font-size: 14px;
  color: #0e9bd2;
  font-weight: 400;
}

.introduce-section {
  margin-top: 39px;
}

.report {
  margin-top: 24px;
}

.report-title {
  font-size: 18px;
  color: #101010;
  font-weight: bold;
}

.report-text {
  font-size: 14px;
  color: #686868;
  font-weight: 400;
  text-align: left;
}

.device {
  background-color: #ffffff;
  padding: 50px 0;
}

.item-dot {
  width: 11px;
  height: 11px;
  background-color: #0e9bd2;
  border-radius: 4px;
}

.form-header {
  background-color: #e3e3e3;
  font-size: 18px;
  color: #717171;
  font-weight: bold;
  padding: 10px 0;
  border-radius: 8px 8px 0 0;
}

.form-content {
  background-color: #ffffff;
}

.form-menu {
  font-size: 16px;
  color: #101010;
  font-weight: 400;
  /* text-align: left; */
}

.form-menu-active {
  /* display: inline-block; */
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  /* text-align: left; */
  position: relative;
}

.form-title-font {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  text-align: left;
}

.form-value-font {
  font-size: 14px;
  color: #101010;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.form-menu-active::after {
  position: absolute;
  width: 50%;
  height: 6px;
  left: 0;
  right: 0;
  margin: auto;
}

.item-box-lg {
  padding: 20px 29px;
  border-bottom: 1px solid #eeeeee;
}

.item-box-xs {
  padding: 10px 19px;
  border-bottom: 1px solid #eeeeee;
}

.check-strip {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 40%;
  height: 6px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #0390d8, #51d2bd);
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #0e9bd2 !important;
}

.ant-tabs-ink-bar {
  background-color: #0e9bd2;
}

.tab-font {
  font-size: 14px;
  font-weight: bold;
}

.mimipro {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--title {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 600;
    color: #000;
  }

  &--tips {
    color: #000;
    font-size: 16px;
  }

  &--qrcode {
    width: 70%;
    margin-top: 30px;
  }
}
</style>
