<template>
  <div class="special-class" style="background-color: #ffffff; padding-bottom: 50px">
    <!-- 头部 -->
    <div class="banner-img">
      <img
        src="/images/report-banner.png"
        width="100%"
        style="min-height: 300px"
        height="auto"
      />
    </div>
    <!-- 主体内容 -->
    <div :style="{ marginTop: fullWidth > 800 ? '-160px' : '0' }">
      <router-view :isMySpecial="isMySpecial" />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      isMySpecial: false,
    };
  },
  methods: {},
  computed: {
    ...mapState(["fullWidth"]),
  },
  created() {
    if (this.$route.query.isMySpecial === "true") {
      this.isMySpecial = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.banner-img {
  overflow: hidden;
  position: relative;
}
</style>
