<template>
    <div class="my-order">
        <!-- banner -->
        <div class="banner-img">
            <img src="/images/report-banner.png" width="100%" height="auto" />
        </div>

        <!-- 我的兑换记录内容 -->
        <a-row type="flex" justify="center">
            <a-col :span="23" :lg="16">
                <a-row style="
              background-color: #ffffff;
              box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
              border-radius: 8px;
            " :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }">
                    <a-col :span="24">
                        <div style="display: flex; align-items: center">
                            <div style="
                    flex: 1;
                    text-align: left;
                    font-size: 18px;
                    color: #333333;
                    font-weight: bold;
                    margin-bottom: 20px;
                  ">
                                我的兑换记录
                            </div>
                        </div>
                    </a-col>
                    <!-- 使用组件 -->
                    <my-records-list></my-records-list>
                </a-row>
            </a-col>
        </a-row>
    </div>
</template>
  
<script>
// 引入我的预约列表组件
import MyRecordsList from "./myRecordsList.vue";
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["fullWidth"]),
    },
    components: {
        MyRecordsList,
    },
};
</script>
  
<style lang="scss" scoped>

</style>