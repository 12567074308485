<template>
  <div class="myreport">
    <!-- banner -->
    <div class="banner-img">
      <img src="/images/report-banner.png" width="100%" height="auto" />
    </div>

    <!-- 我的强制报告列表内容 -->
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <a-col :span="24">
            <div style="display: flex; align-items: center">
              <div
                style="
                  flex: 1;
                  text-align: left;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  margin-bottom: 20px;
                "
              >
                我的强制报告
              </div>
            </div>
          </a-col>
          <!-- 使用组件 -->
          <report-list></report-list>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

// 引入组件
import ReportList from "../components/report/report-list.vue";

// 引入获取强制报告列表接口
export default {
  data() {
    return {};
  },
  components: {
    ReportList,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  methods: {
    ...mapMutations([]),

    // 点击查看详情
    getReportDetails(id) {
      this.$router.push({ name: "MyReportDetails", query: { id } });
    },

    // 分页器方法占位
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
// .temp{
//     height: 0;
//     margin-bottom: 0;
//     border: none;
//     padding: 0;
// }
</style>
