<template>
  <div>
    <div class="banner-img" style="
          position: relative;
          background-image: url(/images/login-banner.jpeg);
          background-size: cover;
          padding-top: 122px;
        ">
      <div style="position: absolute; bottom: 0; z-index: 1">
        <img src="/images/login-banner-bottom.png" width="100%" height="auto" />
      </div>
      <a-row type="flex" align="middle" justify="center" :gutter="24">
        <a-col :lg="8" :span="22">
          <div class="login-enter-box">
            <div class="login-title">
              <span>
                {{ "欢迎登录" }}
              </span>
            </div>
            <a-form :form="form" @submit="login" style="margin-top: 14px">
              <a-form-item :colon="false" style="text-align: left">
                <template #label>手机号</template>
                <a-input style="font-size: 16px" size="large" placeholder="请输入手机号码" v-decorator="[
                  'mobile',
                  { rules: [{ required: true, message: '请输入手机号' }] },
                ]">
                  <template #prefix style="margin-right: 10px">
                    <a-icon type="tablet" style="color: #0e9bd2; font-size: 18px" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item v-if="loginType == 'pwd'" :colon="false" style="text-align: left; margin-bottom: 0">
                <template #label>密码</template>
                <a-input-password v-decorator="[
                  'password',
                  { rules: [{ required: true, message: '请输入密码' }] },
                ]" size="large" allow-clear placeholder="请输入密码">
                  <template #prefix style="margin-right: 10px">
                    <a-icon type="lock" style="color: #0e9bd2; font-size: 18px" />
                  </template>
                </a-input-password>
              </a-form-item>
              <a-form-item v-if="loginType != 'pwd'" :colon="false" style="text-align: left; margin-bottom: 0">
                <template #label>验证码</template>
                <a-input v-decorator="[
                  'captcha',
                  { rules: [{ required: true, message: '请输入验证码' }] },
                ]" style="font-size: 16px" size="large" placeholder="请输入验证码">
                  <template #prefix style="margin-right: 10px">
                    <a-icon type="key" style="color: #0e9bd2; font-size: 18px" />
                  </template>
                  <a-button v-if="!sendCodeStatus" style="font-size: 14px; font-weight: 500; padding: 0 10px"
                    :loading="sendCodeLoading" type="link" slot="addonAfter" @click="sendCode">
                    发送验证码
                  </a-button>
                  <a-button v-if="sendCodeStatus" style="border: none" slot="addonAfter">
                    {{ sendCodeTime }}s
                  </a-button>
                </a-input>
              </a-form-item>
              <div style="
                    display: flex;
                    align-items: center;
                    margin-top: 11px;
                    justify-content: space-between;
                  ">
                <div style="font-size: 14px; color: #101010; font-weight: 400" @click="changeLoginType('code')">
                  <a-button type="link" @click="forgetPopAction"> 忘记密码？ </a-button>
                </div>
                <div style="height: 20px; font-size: 14px; color: #101010; font-weight: 400">
                  <el-dropdown @command="changeLoginType">
                    <span class="el-dropdown-link">
                      切换登录方式<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="pwd">密码登录</el-dropdown-item>
                      <el-dropdown-item command="code">验证码登录</el-dropdown-item>
                      <el-dropdown-item command="erCode">扫码登录</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    width: 100%;
                  ">
                <a-button html-type="submit" style="
                      line-height: 55px;
                      height: 55px;
                      width: 80%;
                      background-color: #0390d8;
                      color: #ffffff;
                      font-weight: bold;
                      border-radius: 27.5px;
                    " :loading="loading">
                  登录
                </a-button>
              </div>
              <div style="margin-top: 20px">
                <a-button type="link" @click="registPopAction"> 新用户注册 </a-button>
              </div>

              <div style="margin-top: 20px">
                <el-checkbox v-model="agreeAgreement"><span style="font-size: 14px; color: #101010">
                    我已阅读并同意守未联盟
                  </span></el-checkbox>
                <span style="color: #0e9bd2" @click="gotoScheme()">《用户服务协议》</span>
              </div>
            </a-form>
          </div>
        </a-col>
        <a-col :lg="10" :span="22">
          <img src="/images/login-img.png" style="width: 100%; height: auto" />
        </a-col>
      </a-row>
    </div>
    <div style="height: 88px"></div>
    <el-dialog :visible.sync="centerDialogVisible" :before-close="handleClose" :show-close="true"
      custom-class="{border-radius: 20px;}" :destroy-on-close="true" width="500px">
      <span slot="title" class="login-title">请使用守未联盟APP扫码登录</span>
      <div class="flex-col-center">
        <qrcode :value="`swlm://scanLogin?key=${key}`" :options="{ width: 300 }"></qrcode>
        <el-button type="text" icon="el-icon-refresh-left" @click="getErcodeInfor">刷新</el-button>
      </div>
    </el-dialog>
    <div style="height: 88px"></div>
    <el-dialog :visible.sync="registDialogVisible" :before-close="handleClose" :show-close="true" width="500px"
      :destroy-on-close="true">
      <span slot="title" class="login-title">{{
        pageType == "regist" ? "账号信息" : "忘记密码"
      }}</span>
      <template v-if="pageType === 'regist' ? true : false">
        <regist @registSuccessEvent="registSuccessEvent"></regist>
      </template>
      <template v-else>
        <forget @modifySuccessEvent="modifySuccessEvent"></forget>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { loginStore } from "../config/api_ajax.js";
import md5 from "js-md5";
import regist from "../components/login/regist.vue";
import forget from "../components/login/forget.vue";
import { timeout } from "q";
export default {
  components: {
    regist,
    forget,
  },
  data() {
    return {
      centerDialogVisible: false,
      registDialogVisible: false,
      loginType: "pwd",
      pageType: "login",
      loading: false,
      sendCodeStatus: false,
      sendCodeTime: 60,
      sendCodeLoading: false,
      agreeAgreement: true,
      key: "",
      timer: undefined,
    };
  },
  computed: {},
  mounted() {
    let array = [];
    array.concat();
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, {
      name: "login_form",
    });
  },
  methods: {
    modifySuccessEvent() {
      console.log("密码修改成功毁掉");
      this.registDialogVisible = false;
    },
    //注册成功
    registSuccessEvent(data) {
      this.registDialogVisible = false;
      this.$HTTP.loginStore(data);
      this.$router.push({ name: "Home" });
    },
    registPopAction() {
      this.pageType = "regist";
      this.registDialogVisible = true;
    },
    forgetPopAction() {
      this.pageType = "forget";
      this.registDialogVisible = true;
    },
    handleClose() {
      this.centerDialogVisible = false;
      this.registDialogVisible = false;
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    gotoScheme() {
      window.open("https://swlmapph5prod.skillhub.top/user-agreement.html");
    },
    changeLoginType(type) {
      console.log(type);
      if (type == "erCode") {
        this.getErcodeInfor();
      } else {
        this.loginType = type;
      }
    },

    checkedAgreement(e) {
      console.log("checkedAgreement", e);
    },

    /**
     * 发送验证码
     */
    sendCode() {
      if (!this.agreeAgreement) {
        this.$elementMessage({
          showClose: false,
          message: "请仔细阅读并同意守未联盟用户服务协议",
          type: "warning",
        });
        return;
      }
      var _self = this;
      this.form.validateFields(["mobile"], (err, values) => {
        if (!values.mobile) {
          // console.log('Received values of form: ', values);
          return;
        } else {
          _self.sendCodeLoading = true;
          let data = {
            mobile: values.mobile,
            type: 1,
          };
          _self.$HTTP
            .httpAppKey({
              url: "/user/getCode",
              data: data,
              method: "post",
            })
            .then((res) => {
              _self.sendCodeLoading = false;
              if (res.code == 0) {
                _self.sendCodeStatus = true;
                let timer = setInterval(() => {
                  _self.sendCodeTime--;
                  if (_self.sendCodeTime == 0) {
                    clearInterval(timer);
                    _self.sendCodeStatus = false;
                    _self.sendCodeTime = 60;
                  }
                }, 1000);
              } else {
                _self.$elementMessage.error("获取验证码失败！");
              }
            })
            .catch((err) => {
              _self.sendCodeLoading = false;
            });
        }
      });
    },
    getErcodeInfor() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      let that = this;
      this.loading = true;
      this.$HTTP
        .http({
          url: "/shouwei/scanCode/login/getQrCode",
          data: {},
          method: "post",
        })
        .then((res) => {
          that.loading = false;
          if (res.code == 0) {
            that.key = res.data.qrCode;
            console.log(that.key);
            that.centerDialogVisible = true;
            that.timer = setInterval(() => {
              that.autoQueryLogin();
            }, 1000);
          } else {
            _self.$elementMessage({
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          that.loading = false;
        });
    },
    //轮询登录
    autoQueryLogin() {
      let _self = this;
      this.$HTTP
        .http({
          url: "/shouwei/scanCode/login/getQRCodeInfo",
          data: {
            qrCode: this.key,
          },
          method: "post",
        })
        .then((res) => {
          if (res.code == 0) {
            if (_self.timer) {
              clearInterval(_self.timer);
              _self.timer = null;
            }
            _self.centerDialogVisible = false;
            _self.$elementMessage({
              message: "登录成功！",
              type: "success",
              duration: 1000,
            });
            _self.$HTTP.loginStore(res.data);
            _self.$router.push({ name: "Home" });
          }
        })
        .catch((err) => {
          that.loading = false;
        });
    },
    login(e) {
      e.preventDefault();
      if (!this.agreeAgreement) {
        this.$elementMessage({
          showClose: false,
          message: "请仔细阅读并同意守未联盟用户服务协议",
          type: "warning",
        });
        return;
      }
      let _self = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          _self.loading = true;
          let data = {
            mobile: values.mobile,
            registerDevType: 5,
            loginType: _self.loginType == "pwd" ? "passwordLogin" : "codeLogin",
          };
          if (_self.loginType == "code" || _self.loginType == "regi") {
            data.code = values.captcha;
          } else {
            data.password = md5(values.password).toLowerCase();
          }
          _self.$HTTP
            .http({
              url: "/shouwei/userAdd/registerUserOrLogin",
              data: data,
              method: "post",
            })
            .then((res) => {
              _self.loading = false;
              if (res.code == 0) {
                _self.$elementMessage({
                  message: "登录成功！",
                  type: "success",
                  duration: 1000,
                });
                _self.$HTTP.loginStore(res.data);
                _self.$router.push({ name: "Home" });
              } else {
                _self.$elementMessage({
                  message: res.msg,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              _self.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 40px;
  font-size: 16px;
}

/deep/ .has-error .ant-form-explain,
.has-error .ant-form-split {
  margin-top: 5px;
}
</style>

<style>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.login-enter-box {
  padding: 34px 38px;
  background-color: #ffffff;
  box-shadow: 0px 0px 17px 0px rgba(14, 155, 210, 20);
  /* position: absolute; */
  /* bottom: 20px; */
  /* left: 21%; */
  border-radius: 8px;
  /* width: 421px; */
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
}

.login-title {
  font-size: 26px;
  color: #101010;
  font-weight: bold;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
