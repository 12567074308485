<template>
  <div class="report-details">
    <!-- banner -->
    <div class="banner-img">
      <img src="/images/report-banner.png" width="100%" height="auto" />
    </div>

    <!-- 详情内容 -->
    <div style="margin-top: -25px" class="myreport-details">
      <a-row type="flex" justify="center">
        <a-col
          :span="23"
          :lg="16"
          style="
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
        >
          <!-- mask -->
          <div
            class="content"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)"
            style="width: 100%"
          >
            <div style="display: flex; justify-content: center">
              <template v-if="ReportDetail ? false : true">
                <a-row
                  type="flex"
                  justify="center"
                  style="
                    margin-bottom: 10px;
                    margin-top: 10px;
                    min-height: 400px;
                  "
                >
                  <el-empty :description="description"></el-empty>
                </a-row>
              </template>
            </div>
            <div class="form-header">
              我的强制报告
              <a href="./MyReport" class="go-back-a" style="color: #37b6df"
                >返回</a
              >
            </div>
            <div class="form-content">
              <a-row class="item-box-lg">
                <a-col :span="12" :lg="4">
                  <div class="form-menu-active">强制报告详情</div>
                  <div class="check-strip"></div>
                </a-col>
              </a-row>
            </div>
            <!-- 报告人 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">报告人</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.name }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>
            <!-- 报告情形 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">报告情形</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.situation }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>
            <!-- 联系方式 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">联系方式</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.tel }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>
            <!-- 所在单位 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">所在单位</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.userUnit }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>
            <!-- 案发地区 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">案发地区</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span
                        >{{ ReportDetail.province }}{{ ReportDetail.city
                        }}{{ ReportDetail.district }}</span
                      >
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>
            <!-- 案发详细地址 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">案发详细地址</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.address }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>
            <!-- 接收报告单位 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">接收报告单位</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.receiveDeptName }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>
            <!-- 报告说明 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">报告说明</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.details }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>
            <!-- 抄送单位 -->
            <!-- <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">抄送单位</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.receiveDept }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row> -->
            <!-- 受害人 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">受害人</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.victim }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>
            <!-- 受害人联系方式 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">受害人联系方式</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.victimTel }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>
            <!-- 受害人身份证号 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">受害人身份证号</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; justify-content: flex-end">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <span>{{ ReportDetail.victimIDNoShow }}</span>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>

            <!-- 详情图片 -->
            <a-row :class="fullWidth > 992 ? 'item-box-lg' : 'item-box-xs'">
              <a-col :span="8" :lg="4">
                <div class="form-title-font">详情图片</div>
              </a-col>
              <a-col :span="16" :lg="20">
                <div style="display: flex; align-items: center">
                  <el-form
                    ref="form"
                    :model="ReportDetail"
                    class="form-value-font"
                  >
                    <el-form-item>
                      <div class="imagesBox">
                        <div v-for="(item, index) in imageList" :key="index">
                          <div class="demo-image__preview imgVideoList">
                            <el-image
                              v-if="item.indexOf('.mp4') === -1"
                              style="width: 100px; height: 100px"
                              :src="item"
                              :preview-src-list="previewImageList"
                            >
                            </el-image>
                            <div v-else style="position: relative">
                              <video
                                ref="video"
                                class="home-video"
                                :src="item"
                                :id="'video' + index"
                                @click="handleClickFullScr(index)"
                              ></video>
                              <div class="icon-box">
                                <i
                                  class="el-icon-video-play playIcon"
                                  @click="handleClickFullScr('video' + index)"
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </a-col>
            </a-row>

            <!-- 返回上一级 -->
            <div
              style="
                width: 300px;
                margin: 30px auto 0 auto;
                padding-bottom: 30px;
              "
            >
              <a-button
                style="
                  border-radius: 25px;
                  background: linear-gradient(90deg, #51d2be, #0090d7);
                  font-size: 15px;
                  color: #fefefe;
                  font-weight: bold;
                  height: 50px;
                  width: 340px;
                  border: none;
                "
                @click="goBackPage()"
                >返回上一级
              </a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      id: "",
      ReportDetail: {}, // 强制报告详情对象
      imageList: [], // 图片数据
      previewImageList: [], // 图片预览列表
      loading: true,
      empty: false,
      description: "暂无数据",
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  methods: {
    ...mapMutations([]),
    // 获取我的强制报表详情数据
    getData() {
      this.loading = true;
      // 获取地址栏中的参数
      this.id = this.$route.query.id;
      console.log("2222222", this.id);
      let _self = this;
      this.$HTTP
        .httpToken({
          url: "/shouwei/report/getReportRecordDetail",
          method: "post",
          data: {
            id: this.id,
          },
        })
        .then((res) => {
          //   loading.close();
          this.loading = false;
          console.log("111", res);
          if (res.code == 0 && res.data) {
            this.empty = false;
            this.$elementMessage({
              showClose: true,
              message: "获取强制报告详情成功",
              type: "success",
            });
            // 结构数据
            let { data } = res;
            console.log(data);

            // 赋值
            this.ReportDetail = { ...data };
            let imagesArray = res.data.annexList;
            // 循环图片数组
            imagesArray.forEach((v, i) => {
              this.imageList.push(v.url);
            });
            console.log("4444", this.imageList);

            // 获取预览图片数据
            this.imageList.forEach((v, i) => {
              if (v.indexOf(".mp4") == -1) {
                this.previewImageList.push(v);
              }
            });
            console.log(this.previewImageList);
          } else {
            this.empty = true;
            this.description = "暂无数据";
            this.$elementMessage.error(res.msg);
          }
        })
        .catch((error) => {
          //   loading.close();
          this.loading = false;
          this.$elementMessage.error(error);
        });
    },
    // 返回上一级
    goBackPage() {
      this.$router.push("/myreport");
    },
    // 点击视频全屏
    handleClickFullScr(id) {
      var ele = document.getElementById(id);
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
        ele.play();
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
        ele.play();
      } else if (ele.webkitRequestFullScreen) {
        ele.webkitRequestFullScreen();
        ele.play();
      }
    },
    // 退出全屏
    // handleExitVideo() {
    //   var de = document.documentElement;
    //   if (de.exitFullscreen) {
    //     de.exitFullscreen();
    //     de.pause();
    //   } else if (de.mozCancelFullScreen) {
    //     de.pause();
    //     de.mozCancelFullScreen();
    //   } else if (de.webkitCancelFullScreen) {
    //     de.pause();
    //     de.webkitCancelFullScreen();
    //   }
    // },
  },
  created() {
    this.getData();
  },
  mounted() {
    // this.handleExitVideo();
    let anchorOffset0 = document
      .querySelector(".myreport-details")
      .getBoundingClientRect().top;
    console.log("top", anchorOffset0);
    window.scrollTo(0, anchorOffset0);
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 80%;
  margin: -30px auto 0 auto;
  position: relative;
  z-index: 10;
}
.imagesBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .details-img {
    height: 160px;
    width: 160px;
    margin-right: 20px;
  }
}

// 详情卡片样式
.form-header {
  background-color: #e3e3e3;
  font-size: 18px;
  color: #717171;
  font-weight: bold;
  padding: 10px 0;
  border-radius: 8px 8px 0 0;
  position: relative;
  .go-back-a {
    position: absolute;
    left: 3%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.form-content {
  background-color: #ffffff;
}

.form-menu {
  font-size: 16px;
  color: #101010;
  font-weight: 400;
  /* text-align: left; */
}

.el-form-item {
  margin-bottom: 0;
}

.form-menu-active {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  text-align: left;
  position: relative;
}

.form-title-font {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  text-align: left;
}

.form-value-font {
  font-size: 14px;
  color: #101010;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.form-menu-active::after {
  position: absolute;
  width: 50%;
  height: 6px;
  left: 0;
  right: 0;
  margin: auto;
}

.item-box-lg {
  padding: 20px 29px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  align-items: center;
}

.item-box-xs {
  padding: 10px 19px;
  border-bottom: 1px solid #eeeeee;
}
.go-back-btn {
  margin: 20px;
}

.videoBox {
  height: 100%;
  width: 100%;
}

.home-video {
  // object-fit: fill; /*这里是关键*/
  width: 100px;
  height: 100px;
}

.imgVideoList {
  display: flex;
}

.playIcon {
  position: absolute;
  font-size: 40px;
  color: #ffffff;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// zou走马灯样式
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
  display: flex;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.img,
.video {
  width: 100%;
  height: 100%;
}
</style>