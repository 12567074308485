<template>
  <div class="special-class" style="background-color: #ffffff; padding-bottom: 50px">
    <!-- 头部 -->
    <div class="banner-img">
      <img
        src="/images/legal-competition.png"
        width="100%"
        style="min-height: 300px"
        height="auto"
      />
    </div>
    <!-- 主体内容 -->
    <div :style="{ marginTop: fullWidth > 800 ? '-160px' : '0' }">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// 引入组件
import LegalCompetitionCard from "../components/legal-competition/legal-competition-card.vue";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["fullWidth"]),
  },
  components: {
    // LegalCompetitionCard,
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
