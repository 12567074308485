<template>
  <div>
    <a-row
      v-if="!isMyCourse"
      type="flex"
      justify="space-between"
      style="margin-bottom: 10px"
    >
      <a-col>
        <el-radio-group v-model="shortType" @change="onShortChange()">
          <el-radio-button
            v-for="(item, i) in shortTypes"
            :label="i"
            :value="i"
            :key="i"
            >{{ item }}</el-radio-button
          >
        </el-radio-group>
        <el-cascader
          class="pform--content--row--value"
          placeholder="选择课程类型"
          :show-all-levels="true"
          v-model="category"
          :options="categoryList"
          :props="{
            label: 'name',
            value: 'name',
            children: 'childrenList',
          }"
          @change="handleCateChange"
          ref="cateList"
        ></el-cascader>
      </a-col>
      <a-col>
        <el-input placeholder="搜索云课堂" v-model="keyword" @change="onSearchTextChange">
          <i
            v-if="!isEmpty(keyword)"
            class="el-icon-circle-close el-input__icon"
            slot="suffix"
            @click="handleClearSearchClick"
          >
          </i>
          <el-button slot="append" icon="el-icon-search" @click="search()"></el-button>
        </el-input>
      </a-col>
    </a-row>
    <el-tabs v-else v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="我预约的" name="first"></el-tab-pane>
      <el-tab-pane label="我观看的" name="second"></el-tab-pane>
      <el-tab-pane label="我发布的" name="third"></el-tab-pane>
    </el-tabs>
    <template v-if="empty">
      <a-row
        type="flex"
        justify="center"
        style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
      >
        <el-empty :description="description"></el-empty>
      </a-row>
    </template>
    <template v-else>
      <a-row
        :gutter="12"
        justify="start"
        :wrap="false"
        style="margin-top: 20px; min-height: 500px"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.2)"
      >
        <a-col
          :span="12"
          :lg="6"
          v-for="(item, index) in listData"
          :key="item.classRoomId"
          @click="onItemClick(index)"
        >
          <div
            style="
              box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);
              border-radius: 8px;
              overflow: hidden;
              margin-bottom: 24px;
              cursor: pointer;
            "
          >
            <div style="position: relative; overflow: hidden">
              <img class="img-class" :src="item.coverUrl" fit="cover" />

              <template v-if="item.type <= 2">
                <!-- 预约中 -->
                <div
                  v-if="item.runStatus == 0"
                  style="
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(255, 209, 0, 0.46);
                    padding: 4px 8px;
                    border-radius: 8px 0 8px 0;
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="12"
                    height="12"
                    style="
                      fill: rgba(255, 255, 255, 1);
                      border-color: rgba(0, 0, 0, 0);
                      border-width: 0px;
                    "
                    filter="none"
                  >
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"
                    />
                  </svg>
                  <div
                    style="
                      font-size: 12px;
                      color: #ffffff;
                      font-weight: 400;
                      margin-left: 3px;
                    "
                  >
                    预约中
                  </div>
                </div>
                <!-- 直播中 -->
                <div
                  v-else-if="item.runStatus == 1"
                  style="
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(14, 155, 210, 0.46);
                    padding: 4px 8px;
                    border-radius: 8px 0 8px 0;
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="12"
                    height="12"
                    style="
                      fill: rgba(255, 255, 255, 1);
                      border-color: rgba(0, 0, 0, 0);
                      border-width: bpx;
                      border-style: undefined;
                    "
                    filter="none"
                  >
                    <path
                      d="M21 6h-7.59l3.29-3.29L16 2l-4 4-4-4-.71.71L10.59 6H3a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8a2 2 0 0 0-2-2zm0 14H3V8h18v12zM9 10v8l7-4z"
                    />
                  </svg>
                  <div
                    style="
                      font-size: 12px;
                      color: #ffffff;
                      font-weight: 400;
                      margin-left: 3px;
                    "
                  >
                    直播中
                  </div>
                </div>
                <!-- 已结束 -->
                <div
                  v-else
                  style="
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(14, 155, 210, 0.46);
                    padding: 4px 8px;
                    border-radius: 8px 0 8px 0;
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="12"
                    height="12"
                    style="
                      fill: rgba(255, 255, 255, 1);
                      border-color: rgba(0, 0, 0, 0);
                      border-width: bpx;
                      border-style: undefined;
                    "
                    filter="none"
                  >
                    <path
                      d="M21 6h-7.59l3.29-3.29L16 2l-4 4-4-4-.71.71L10.59 6H3a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8a2 2 0 0 0-2-2zm0 14H3V8h18v12zM9 10v8l7-4z"
                    />
                  </svg>
                  <div
                    style="
                      font-size: 12px;
                      color: #ffffff;
                      font-weight: 400;
                      margin-left: 3px;
                    "
                  >
                    已结束
                  </div>
                </div>
              </template>
              <template v-else>
                <!-- 播放按钮 -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  width="24"
                  height="24"
                  style="
                    fill: rgba(255, 255, 255, 0.67);
                    border-color: rgba(0, 0, 0, 0);
                    border-width: bpx;
                    border-style: undefined;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                  "
                  filter="drop-shadow(0px 0px 6px rgba(0,0,0,0.4))"
                >
                  <path
                    d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                  ></path>
                </svg>
              </template>
            </div>
            <div style="padding: 8px">
              <div
                class="u-line-2"
                style="
                  font-size: 12px;
                  color: #101010;
                  font-weight: 400;
                  height: 36px;
                  margin-bottom: 8px;
                  text-align: left;
                "
              >
                {{ item.name }}
              </div>
              <div class="course-cate">
                <div
                  class="mt-8"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-top: 0;
                  "
                  v-if="item.courseTypeList && item.courseTypeList.length > 0"
                >
                  <div
                    style="
                      font-size: 12px;
                      color: #0e9bd2;
                      background-color: #ddf6ff;
                      border: 1px solid #0e9bd2;
                      border-radius: 4px;
                      padding: 2px 10px;
                      margin-right: 5px;
                    "
                  >
                    {{ item.courseTypeList[0].name }}
                  </div>
                </div>
              </div>
              <div
                class="mt-8"
                style="
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;
                  justify-content: space-between;
                "
              >
                <div style="display: flex; flex-direction: row; align-items: center">
                  <img
                    :src="item.avatar"
                    width="18px"
                    height="18px"
                    style="border-radius: 50%"
                  />
                  <div
                    style="
                      font-size: 12px;
                      color: #868686;
                      font-weight: 400;
                      margin-left: 6px;
                      word-break: keep-all;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ showName(item) }}
                  </div>
                </div>
                <div
                  style="
                    font-size: 12px;
                    color: #a8a8a8;
                    word-break: keep-all;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ `${item.viewCount}人已参加` }}
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
      <!-- 分页器 -->
      <a-row
        v-if="!isMyCourse"
        type="flex"
        justify="center"
        style="margin-bottom: 10px; margin-top: 10px"
      >
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </a-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "ClassroomList",
  props: {
    departmentId: {
      type: String,
      default: "",
    },
    isMyCourse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeName: "first",
      listData: [],
      shortType: 0,
      category: [],
      shortTypes: ["综合排序", "人气排序"],
      categoryList: [{ id: "", name: "全部类型" }],
      keyword: "",
      loading: true,
      empty: false,
      totalCount: 0,
      cityCode: 520500,
      pageIndex: 1,
      pageSize: 12,
      description: "暂无数据",
    };
  },
  watch: {
    keyword(newVal, val) {
      if (this.isEmpty(newVal)) {
        this.pageIndex = 1;
        this.getListData();
      }
    },
  },
  mounted() {
    this.getCourseCateAction();
    this.getListData();
  },
  methods: {
    handleCateChange(value) {
      console.log(value);
      this.getListData();
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.shortType = Number.parseInt(tab.index);
      // console.log("this.shortType", this.shortType);
      this.getListData();
    },
    showName(item) {
      return item.companyName ? item.companyName : item.nickName;
    },
    getCourseCateAction() {
      this.$HTTP
        .httpToken({
          url: "/shouwei/course/findCourseTypeListV2All",
          method: "post",
          data: {},
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            this.categoryList = res.data;
            this.categoryList.forEach((item) => {
              if (item.childrenList) {
                item.childrenList.forEach((subItem) => {
                  subItem.childrenList = undefined;
                });
              }
            });
            this.categoryList.unshift({ id: "", name: "全部类型" }); //数组添加第一个元素
          }
        })
        .catch((e) => {});
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getListData();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getListData();
    },
    // 点击进入课程详情页面
    onItemClick(index) {
      this.$router.push({
        path: "/classroom/details",
        query: { id: this.listData[index].classRoomId },
      });
    },
    onSearchTextChange(e) {
      console.log("onSearchTextChange", e);
      if (this.isEmpty(e)) {
        this.pageIndex = 1;
        this.keyword = "";
        this.getListData();
      }
    },
    handleClearSearchClick(e) {
      if (!this.isEmpty(this.keyword)) {
        this.pageIndex = 1;
        this.keyword = "";
        this.getListData();
      }
    },
    onShortChange() {
      this.pageIndex = 1;
      this.getListData();
    },
    onTypeChange() {
      this.pageIndex = 1;
      this.shortType = 0;
      this.getListData();
    },
    search() {
      this.pageIndex = 1;
      this.getListData();
    },
    getListData() {
      this.loading = true;
      let params = {
        cityCode: this.cityCode,
        type: this.shortType,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        keyword: this.keyword,
      };
      if (!this.isEmpty(this.departmentId)) {
        params = Object.assign(params, { departmentId: this.departmentId });
      }
      if (this.category.length != 0) {
        let tc = this.category.at(-1); //取倒数第一个
        if (tc != "全部类型") {
          params = Object.assign(params, { courseTypeNameList: [tc] });
        }
      }
      if (this.isMyCourse) {
        params = {
          type: this.shortType + 1,
          lastId:
            this.listData.length > 0 ? this.listData[this.listData.length - 1].id : "",
        };
      }
      this.$HTTP
        .httpToken({
          url: this.isMyCourse
            ? "/classroom/getMyCloudClassRoomInfoList"
            : "/classroom/getCloudClassRoomInfoPage",
          method: "post",
          data: params,
        })
        .then((res) => {
          this.loading = false;
          if (this.isMyCourse) {
            if (res.code == 0 && res.data && res.data.length > 0) {
              this.empty = false;
              this.listData = res.data;
            } else {
              if (this.pageIndex == 1) {
                this.empty = true;
                this.description = "暂无数据";
              } else {
                this.empty = false;
              }
            }
          } else {
            if (res.code == 0 && res.data && res.data.list && res.data.list.length > 0) {
              this.empty = false;
              this.totalCount = res.data.pageCount;
              this.listData = res.data.list;
            } else {
              if (this.pageIndex == 1) {
                this.empty = true;
                this.description = "暂无数据";
              } else {
                this.empty = false;
              }
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          if (this.pageIndex == 1) {
            this.empty = true;
            this.description = e;
          } else {
            this.empty = false;
          }
        });
    },
  },
};
</script>

<style lang="scss">
$w: 100%;
$rate: 16/9;
.img-class {
  width: 100%;
  height: 140px;
}
.course-cate {
  height: 40px;
}
.mt-8 {
  margin-top: 8px;
}
</style>
