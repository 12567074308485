<template>
	<div style="display: flex;align-items: center;flex-wrap: wrap;border-bottom: 1px solid #F0F0F0;">
		<div v-for="(item,index) in list" :key="index" :class="nowTab == item.value ? 'tabs-active-font' : 'tabs-default-font'" @click="item.value != nowTab ? cutTabs(item.value) : ''">
			{{item.name}}{{item.count ? `(${item.count})` : ''}}
		</div>
	</div>
</template>

<script>
	export default {
		name: 'CustomTabs',
		props: {
			list: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				nowTab: 'all'
			}
		},
		methods: {
			cutTabs(value) {
				this.nowTab = value
			}
		}
	}
</script>

<style>
	.tabs-default-font {
		font-size: 14px;
		color: #101010;
		font-weight: bold;
		margin: 0 20px 20px 0;
		text-align: center;
		cursor: pointer;
	}

	.tabs-active-font {
		font-size: 16px;
		color: #0E9BD2;
		font-weight: 500;
		margin: 0 20px 20px 0;
		position: relative;
		text-align: center;
	}

	.tabs-active-font::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin: auto;
		width: 50%;
		background-color: #0E9BD2;
		border-radius: 99px;
		height: 6px;
	}
</style>
