<template>
  <div class="bg-class">
    <div class="bg-class--from">
      <el-form :model="ruleForm" label-width="70px" :rules="rules" ref="ruleForm">
        <el-form-item label="手机号" prop="phone">
          <el-input
            prefix-icon="el-icon-mobile"
            clearable
            v-model.number="ruleForm.phone"
            placeholder="请输入11位手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input
            prefix-icon="el-icon-mobile-phone"
            clearable
            v-model="ruleForm.code"
            placeholder="请输入4位验证码"
          >
            <template slot="append">
              <el-button
                type="primary"
                :disabled="sendCodeStatus"
                :loading="sendCodeLoading"
                @click="sendCode"
                >{{
                  sendCodeTime == 60 ? "发送验证码" : `${sendCodeTime}秒后重发`
                }}</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="输入密码" prop="password">
          <el-input
            prefix-icon="el-icon-unlock"
            clearable
            v-model="ruleForm.password"
            show-password
            placeholder="请输入6-16位新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确定密码" prop="secondPassword">
          <el-input
            prefix-icon="el-icon-unlock"
            clearable
            v-model="ruleForm.secondPassword"
            show-password
            placeholder="请再次输入密码"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <el-button
      type="primary"
      :disabled="submitLoading"
      :loading="submitLoading"
      round
      class="submitButton"
      @click="onSubmit"
      >确定</el-button
    >
  </div>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入6-16位新密码!"));
      } else {
        if (value.length < 6 || value.length > 16) {
          callback(new Error("请输入6-16位新密码!"));
        } else {
          this.$refs.ruleForm.validateField("secondPassword");
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validateMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error("手机号格式错误"));
        } else {
          console.log(value);
          if (`${value}`.length != 11) {
            callback(new Error("请输入11位手机号"));
          } else {
            callback();
          }
        }
      }, 1000);
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        if (`${value}`.length != 4) {
          callback(new Error("请输入4位验证码"));
        } else {
          callback();
        }
      }
    };
    return {
      formLabelWidth: 100,
      sendCodeTime: 60,
      sendCodeLoading: false,
      submitLoading: false,
      sendCodeStatus: false,
      ruleForm: {
        phone: "",
        password: "",
        secondPassword: "",
        code: "",
      },
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        secondPassword: [{ validator: validatePass2, trigger: "blur" }],
        phone: [{ validator: validateMobile, trigger: "change" }],
        code: [{ validator: validateCode, trigger: "blur" }],
      },
    };
  },
  emits: ["modifySuccessEvent"],
  methods: {
    onSubmit() {
      var _self = this;
      console.log("点击提交");
      this.$refs["ruleForm"].validate((success, object) => {
        if (success) {
          _self.submitLoading = true;
          let data = {
            mobile: _self.ruleForm.phone,
            code: _self.ruleForm.code,
            password: md5(_self.ruleForm.password).toLowerCase(),
          };
          _self.$HTTP
            .httpAppKey({
              url: "/shouwei/user/updateUserPassword",
              data: data,
              method: "post",
            })
            .then((res) => {
              _self.submitLoading = false;
              if (res.code == 0) {
                _self.$elementMessage.success("密码修改成功");
                _self.$emit("modifySuccessEvent", "密码修改成功");
              } else {
                _self.$elementMessage.error(res.msg);
              }
            })
            .catch((err) => {
              _self.submitLoading = false;
            });
        }
      });
    },
    sendCode() {
      var _self = this;
      this.$refs["ruleForm"].validateField(["phone"], (err) => {
        if (err && err.length != 0) {
          return;
        } else {
          _self.sendCodeLoading = true;
          let data = {
            mobile: _self.ruleForm.phone,
          };
          _self.$HTTP
            .httpAppKey({
              url: "/user/getCode",
              data: data,
              method: "post",
            })
            .then((res) => {
              console.log(1231313113131000000);
              _self.sendCodeLoading = false;
              if (res.code == 0) {
                _self.sendCodeStatus = true;
                let timer = setInterval(() => {
                  _self.sendCodeTime--;
                  if (_self.sendCodeTime == 0) {
                    clearInterval(timer);
                    _self.sendCodeStatus = false;
                    _self.sendCodeTime = 60;
                  }
                }, 1000);
              } else {
                _self.$elementMessage.error("获取验证码失败！");
              }
            })
            .catch((err) => {
              console.log(1231313113131);
              _self.sendCodeLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.bg-class {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  &--from {
    width: 400px;
  }
}
.submitButton {
  width: 60%;
  margin-left: 35px;
  margin-top: 40px;
}
</style>
