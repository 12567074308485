<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          " :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }">
          <a-col :span="24" style="border-bottom: 1px solid #f0f0f0; padding-bottom: 18px">
            <span style="font-size: 18px; color: #333333; font-weight: bold">青少年法治教育云上基地</span>
          </a-col>
          <a-spin tip="Loading..." :spinning="cloudBaseLoading">
            <a-col :span="24" v-if="cloudBaseList.length <= 0">
              <a-empty description="暂无云上基地" />
              <a-divider></a-divider>
            </a-col>
            <a-col :span="24" style="margin-top: 15px; text-align: left" v-if="cloudBaseList.length > 0">
              <a-row :gutter="[21, 24]">
                <a-col :span="24" :lg="8" v-for="(item, index) in cloudBaseList" :key="index">
                  <div style="
                      position: absolute;
                      right: 11px;
                      top: 25px;
                      background-color: #ecda2a;
                      border-radius: 8px 0 0 8px;
                      padding: 4px 6px 4px 5px;
                      box-shadow: -2px 0px 6px 0px rgba(0, 0, 0, 0.2);
                      font-size: 14px;
                      color: #555555;
                      font-weight: 400;
                      cursor: pointer;
                      z-index: 10000;
                    " @click="applyViewBase(item)">
                    实地参观
                  </div>
                  <div style="position: relative;
                      height: 0;
                      padding-bottom: 48%;overflow: hidden;">
                    <img :src="item.cover" style="
                      width: 100%;
                      box-sizing: border-box;
                      /* aspect-ratio:16/9; */
                      object-fit: cover;
                      border-radius: 8px;
                    " />
                  </div>
                  <div style="display: flex; align-items: center; margin-top: 7px">
                    <div class="u-line-1" style="
                        flex: 1;
                        font-size: 16px;
                        color: #101010;
                        font-weight: 500;
                        margin-right: 10px;
                      ">
                      {{ item.baseName }}
                    </div>
                    <a-button type="primary" @click="gotoVr(item.vrUrl)" style="
                        background-color: #0e9bd2;
                        border: none;
                        padding: 0 5px;
                        font-size: 14px;
                        color: #ffffff;
                        font-weight: 500;
                        flex-shrink: 0;
                      ">
                      在线参观
                    </a-button>
                  </div>
                  <div style="
                      display: flex;
                      align-items: center;
                      margin-top: 6px;
                      font-size: 12px;
                      color: #868686;
                      font-weight: 400;
                    ">
                    <div class="u-line-1" style="flex: 1">
                      {{ item.address }}
                    </div>
                    <div style="flex-shrink: 0">
                      {{ item.viewCount }}人已参观
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-spin>
        </a-row>
      </a-col>
    </a-row>
    <!-- 选择服务类型弹窗 -->
    <a-modal v-model="visible" :closable="false" :footer="null" centered>
      <div class="modal-header">
        <div style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          ">
          <div style="position: absolute; left: 0" @click="visible = false">
            <a-icon type="arrow-left" style="color: #6d6d6d; font-size: 18px" />
          </div>
          <div style="
              text-align: center;
              font-size: 18px;
              color: #101010;
              font-weight: bold;
            ">
            实地预约参观
          </div>
        </div>
        <a-row style="margin-top: 5px">
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
            ">
            <div style="
                flex: 1;
                font-size: 16px;
                color: #101010;
                font-weight: bold;
              ">
              基地名称
            </div>
            <div style="text-align: right">
              <div style="font-size: 14px; color: #101010; font-weight: 400">
                {{ nowBaseInfo.baseName }}
              </div>
              <div style="
                  font-size: 12px;
                  color: #aaaaaa;
                  font-weight: 400;
                  margin-top: 2px;
                ">
                开放时间 {{ nowBaseInfo.openingHours }}
              </div>
            </div>
          </a-col>
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
            ">
            <div style="
                flex: 1;
                font-size: 16px;
                color: #101010;
                font-weight: bold;
              ">
              单位/组织
            </div>
            <div>
              <a-input placeholder="请填写单位/组织" v-model="formData.group" style="border: none; text-align: right">
              </a-input>
            </div>
          </a-col>
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
            ">
            <div style="
                flex: 1;
                font-size: 16px;
                color: #101010;
                font-weight: bold;
              ">
              参观人数
            </div>
            <div>
              <a-input placeholder="请输入参观人数" style="border: none; text-align: right" v-model="formData.count"></a-input>
            </div>
          </a-col>
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
            ">
            <div style="
                flex: 1;
                font-size: 16px;
                color: #101010;
                font-weight: bold;
              ">
              预约时间
            </div>
            <div style="text-align: right">
              <el-date-picker v-model="formData.visitDate" type="datetime" placeholder="选择日期时间">
              </el-date-picker>
            </div>
          </a-col>
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
            ">
            <div style="
                flex: 1;
                font-size: 16px;
                color: #101010;
                font-weight: bold;
              ">
              预约人姓名
            </div>
            <div>
              <a-input placeholder="请输入预约人名称" v-model="formData.name" style="border: none; text-align: right"></a-input>
            </div>
          </a-col>
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
            ">
            <div style="
                flex: 1;
                font-size: 16px;
                color: #101010;
                font-weight: bold;
              ">
              预约人电话
            </div>
            <div>
              <a-input placeholder="请输入预约人电话" v-model="formData.phone" :max-length="maxLength" type="number"
                style="border: none; text-align: right"></a-input>
            </div>
          </a-col>
          <!-- 预约须知 -->
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
              font-size: 12px;
            ">
            {{ nowBaseInfo.tipText }}
          </a-col>
          <a-col :span="24">
            <div style="
                font-size: 16px;
                color: #101010;
                font-weight: bold;
                padding: 15px 0;
              ">
              需求说明
            </div>
            <div>
              <a-textarea placeholder="请输入需求内容" v-model="formData.explain" :rows="4"
                style="background-color: #f4f4f4; border: none" />
            </div>
          </a-col>
          <a-col :span="24" style="margin-top: 34px">
            <div style="width: 50%; margin: auto">
              <a-button type="primary" block style="
                  height: 50px;
                  background-color: #0e9bd2;
                  font-size: 16px;
                  color: #ffffff;
                  font-weight: 400;
                " :loading="modalSubmitLoading" @click="modalFormSubmit">提交</a-button>
            </div>
          </a-col>
        </a-row>
      </div>
    </a-modal>
    <!-- 提交成功弹出框 -->
    <a-modal v-model="submitSuccessFlag" :closable="false" :footer="null" centered>
      <div class="modal-header">
        <!-- 恭喜你 预约成功 -->
        <div style="
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
          ">
          <div style="text-align: center">
            <img src="../../../public/images/my-order-success.svg" style="height: 120px" />
          </div>
          <div style="
              text-align: center;
              font-size: 18px;
              color: #101010;
              font-weight: bold;
            ">
            恭喜你，预约成功！
          </div>
          <div style="
              text-align: center;
              font-size: 18px;
              color: #101010;
              font-weight: bold;
            ">
            正在审核
          </div>
        </div>
        <!-- 预约人 -->
        <a-row style="margin-top: 5px">
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
            ">
            <div style="
                flex: 1;
                font-size: 16px;
                color: #101010;
                font-weight: bold;
              ">
              预约人
            </div>
            <div style="text-align: right">
              <div style="font-size: 14px; color: #101010; font-weight: 400">
                {{ formData.name }}
              </div>
            </div>
          </a-col>
        </a-row>
        <!-- 预约时间 -->
        <a-row style="margin-top: 5px">
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
            ">
            <div style="
                flex: 1;
                font-size: 16px;
                color: #101010;
                font-weight: bold;
              ">
              预约时间
            </div>
            <div style="text-align: right">
              <div style="font-size: 14px; color: #101010; font-weight: 400">
                {{ formData.visitDate }}
              </div>
            </div>
          </a-col>
        </a-row>
        <!-- 基地联系电话 -->
        <a-row style="margin-top: 5px">
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
            ">
            <div style="
                flex: 1;
                font-size: 16px;
                color: #101010;
                font-weight: bold;
              ">
              基地联系电话
            </div>
            <div style="text-align: right">
              <div style="font-size: 14px; color: #101010; font-weight: 400">
                {{ nowBaseInfo.contactMobile }}
              </div>
            </div>
          </a-col>
        </a-row>
        <!-- 提示信息 -->
        <a-row style="margin-top: 5px">
          <a-col :span="24" style="
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeeee;
            ">
            <span>审核结果将会在48小时内以消息的形式发送给您，请注意查收！</span>
          </a-col>
        </a-row>
        <!-- 查看我的预约 -->
        <div style="
            margin: 30px auto 0 auto;
            padding-bottom: 30px;
            text-align: center;
          ">
          <a-button style="
              border-radius: 25px;
              background: linear-gradient(90deg, #51d2be, #0090d7);
              font-size: 15px;
              color: #fefefe;
              font-weight: bold;
              height: 50px;
              width: 340px;
              border: none;
            " @click="goMyOrder()">查看我的预约
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations } from "vuex";
export default {
  name: "CloudBaseList",
  data() {
    return {
      cloudBaseLoading: false,
      cloudBaseList: [],
      visible: false,
      modalSubmitLoading: false,
      nowBaseInfo: {},
      formData: {
        visitDate: "",
        name: "",
        explain: "",
        group: "",
        count: "",
        phone: "",
      },
      submitSuccessFlag: false,
      maxLength: 11,
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  activated() {
    this.getClouadBaseList();
  },
  methods: {
    ...mapMutations([]),
    gotoVr(url) {
      if (!this.isEmpty(url)) {
        window.open(url);
      } else {
        this.$elementMessage({
          showClose: false,
          message: "暂未开放，敬请关注！",
          type: "error",
        });
      }
    },
    // 查看我的预约
    goMyOrder() {
      this.$router.push("/myorder");
    },
    getClouadBaseList() {
      let data = {
        areaId: "5f574915b191a62dc8701b7f",
      };
      this.cloudBaseLoading = true;
      this.$HTTP
        .http({
          url: "/shouwei/edu/base/list",
          data: data,
          method: "post",
        })
        .then((res) => {
          console.log("云上基地列表", res);
          this.cloudBaseLoading = false;
          if (res.code == 0) {
            this.cloudBaseList = res.data;
          } else {
            this.$elementMessage.error("获取云上基地列表失败！");
          }
        });
    },

    /**
     * 申请实地参观云上基地
     */
    applyViewBase(data) {
      console.log(data);
      this.nowBaseInfo = data;
      this.visible = true;
    },

    /**
     * 提交实地预约参观表单
     */
    modalFormSubmit() {
      this.formData.visitDate = this.timeTranslate(this.formData.visitDate);
      let data = {
        makeAppointment: {
          name: this.formData.group,
          visitCount: this.formData.count,
          userName: this.formData.name,
          tel: this.formData.phone,
          leavingMessage: this.formData.explain,
          baseId: this.nowBaseInfo.id,
          visitTime: this.formData.visitDate,
          areaId: "5f574915b191a62dc8701b7f",
        },
      };
      if (
        this.formData.group === "" &&
        this.formData.count === "" &&
        this.formData.name === "" &&
        this.formData.phone === ""
      ) {
        this.$message.error("请将信息填写完整！");
        return;
      } else {
        this.modalSubmitLoading = true;
        this.$HTTP
          .httpToken({
            method: "post",
            url: "/shouwei/exhibition/hall/saveMakeAppointment",
            data: data,
          })
          .then((res) => {
            this.modalSubmitLoading = false;
            if (res.code == 0) {
              this.$elementMessage({
                showClose: false,
                message: "提交成功",
                type: "success",
              });
              this.visible = false;
              this.submitSuccessFlag = true;
            }
          })
          .catch((e) => {
            this.modalSubmitLoading = false;
            this.$elementMessage({
              showClose: false,
              message: err,
              type: "error",
            });
          });
      }
    },
    timeTranslate(val) {
      // 格式化时间2022-07-05T09:57:39.000Z 成 2018-08-07 00:00:00
      return this.dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .ant-input:focus {
  border: none;
  box-shadow: none;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<style>
.introduce {
  padding: 29px 0 40px 0;
  background-color: #ffffff;
}

.introduce-title {
  font-size: 30px;
  color: #464646;
  font-weight: bold;
}

.introduce-text {
  font-size: 14px;
  color: #0e9bd2;
  font-weight: 400;
}

.introduce-section {
  margin-top: 39px;
}

.report {
  margin-top: 24px;
}

.report-title {
  font-size: 18px;
  color: #101010;
  font-weight: bold;
}

.report-text {
  font-size: 14px;
  color: #686868;
  font-weight: 400;
  text-align: left;
}

.device {
  background-color: #ffffff;
  padding: 50px 0;
}

.item-dot {
  width: 11px;
  height: 11px;
  background-color: #0e9bd2;
  border-radius: 4px;
}

.form-header {
  background-color: #e3e3e3;
  font-size: 18px;
  color: #717171;
  font-weight: bold;
  padding: 10px 0;
  border-radius: 8px 8px 0 0;
}

.form-content {
  background-color: #ffffff;
}

.form-menu {
  font-size: 16px;
  color: #101010;
  font-weight: 400;
  /* text-align: left; */
}

.form-menu-active {
  /* display: inline-block; */
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  /* text-align: left; */
  position: relative;
}

.form-title-font {
  font-size: 16px;
  color: #101010;
  font-weight: bold;
  text-align: left;
}

.form-value-font {
  font-size: 14px;
  color: #101010;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
}

.form-menu-active::after {
  position: absolute;
  width: 50%;
  height: 6px;
  left: 0;
  right: 0;
  margin: auto;
}

.item-box-lg {
  padding: 20px 29px;
  border-bottom: 1px solid #eeeeee;
}

.item-box-xs {
  padding: 10px 19px;
  border-bottom: 1px solid #eeeeee;
}

.check-strip {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 40%;
  height: 6px;
  border-radius: 20px;
  background-image: linear-gradient(to right, #0390d8, #51d2bd);
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #0e9bd2 !important;
}

.ant-tabs-ink-bar {
  background-color: #0e9bd2;
}

.tab-font {
  font-size: 14px;
  font-weight: bold;
}
</style>
